/**
 * PCID 기능 모음
 */
export class Pcid {
  constructor(utilThis) {
    this._funcs = utilThis;
  }

  /**
   * set pcid
   */
  set() {
    const today = new Date();
    const expiredDate = today.getDate() + 30;
    let val = this._funcs.cookie.get(this._funcs.config.cookieName.PCID);

    if (!val) {
      val = (function (prefix) {
        let rtn = '';
        for (let i = 0; i < 10; i++) {
          rtn += String(Math.random()).charAt(2);
        }
        return prefix + rtn;
      })(today.getTime());
    }

    this._funcs.cookie.set(this._funcs.config.cookieName.PCID, val, expiredDate);
  }

  /**
   * get pcid
   * @returns pcid
   */
  get() {
    try {
      const val = this._funcs.cookie.get(this._funcs.config.cookieName.PCID);
      return val;
    } catch (e) {}
    return -1;
  }

  /**
   * get last pcid
   * @returns pcid의 마지막 글자
   */
  getLast() {
    return this.get().slice(-1);
  }
}
