/**
 * device 정보
 * - mobile, tablet, pc => navigator 기준
 * - breakpoint pageType => breakpoint 기준
 */
export class Device {
  constructor(utilThis) {
    const agent = getAgentString();
    this._funcs = utilThis;

    // isMobile 탐지 방법 agent 탐색에서 기능 탐색으로 변경 2023/06/13
    // this.mobile = /android|mobile|ip(hone|od|ad)|blackberry|iemobile|samsungbrowser|kindle|netfront|silk-accelerated|(hpw|web)os|fennec|minimo|opera m(obi|ini)|blazer|dolfin|dolphin|skyfire|zune/.test( agent);
    this.mobile = 'ontouchstart' in document.documentElement;
    this.tablet = this.mobile && !/mobile/.test(agent);
    this.pc = !this.mobile && !this.tablet;
    this.ios = /ipad|iphone|ipod/.test(agent);
    this.and = /android/.test(agent);
    this.joongangApp = /joongangilbo/.test(agent);
    this.msie = (navigator.appName === 'Netscape' && agent.indexOf('trident') !== -1) || agent.indexOf('msie') !== -1;
    this.msieVersion = (function () {
      const re = /MSIE ([0-9]{1,}[\.0-9]{0,})/.exec(navigator.userAgent);
      return re ? parseFloat(RegExp.$1) : -1;
    })();

    // api에서 사용하는 공유디바이스 조건 (P: PC, M: MobileWeb, T: TabletWeb, A: MobileAPP, B: TabletAPP)
    this.dev = this.pc ? 'P' : this.tablet && this.joongangApp ? 'B' : this.tablet ? 'T' : this.joongangApp ? 'A' : 'M';
  }

  // breakpoint 기준 pageType
  getPageType() {
    const ww = this._funcs.getWindowSize().width;

    return ww < this._funcs.config.breakpointMedium
      ? 'mobile'
      : ww < this._funcs.config.breakpointLarge
      ? 'tablet'
      : ww < this._funcs.config.breakpointXlarge
      ? 'pc'
      : 'wide';
  }
}

function getAgentString() {
  if (window.CYPRESS_TEST_AGENT) {
    return window.CYPRESS_TEST_AGENT.toLowerCase();
  }
  return navigator.userAgent.toLowerCase();
}
