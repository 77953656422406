import '@/_modules/utils/utils/class/utils.polyfill';
import { Utils } from '@/_modules/utils/utils/class/utils.index';

const config = {
  webPath: window.WEB_PATH,
  apiPath: window.API_PATH,
  imgPath: window.IMG_PATH,
  pdsPath: window.PDS_PATH,
  cruzPath: window.CRUZ_PATH,
  accountApiPath: window.ACCOUNT_API_PATH,
  paymentApiPath: window.PAYMENT_API_PATH,
  jtbcNewsPath: window.JTBC_NEWS_PATH,
  jtbcCounterPath: window.JTBC_COUNTER_PATH,
  breakpointMedium: window.BREAKPOINT_MEDIUM,
  breakpointLarge: window.BREAKPOINT_LARGE,
  breakpointXlarge: window.BREAKPOINT_XLARGE,
  cookieName: window.COOKIE_NAME,
  domainCookieInfo: {
    PATH: '/',
    DOMAIN: window.Cypress && window.location === 'localhost' ? 'localhost' : '.joongang.co.kr',
  },
};

export function setMyList(newMyList) {
  window.myList = newMyList;
}
export function setMyListLoading(newMyListLoading) {
  window.myListLoading = newMyListLoading;
}

export function setMyFavoList(newMyFavoList) {
  window.myFavoList = newMyFavoList;
}

export function setMyFavoListLoading(newMyFavoListLoading) {
  window.myFavoListLoading = newMyFavoListLoading;
}

export function setFbAsyncInit(initFunction) {
  window.fbAsyncInit = initFunction;
}

const utils = new Utils(config);

window.utils = utils;
