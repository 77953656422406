const { $ } = window;

/**
 * 문자열 포맷 기능 모음
 * 1) 편집기사 날짜 변환 (yyyy-MM-DD HH:mm:ss) => data-format="timeAgo"
 * 2) 라이브 기사 날짜 변환 (yyyy-MM-DD HH:mm:ss) => data-format="liveTime"
 */
export class Format {
  constructor(utilThis) {
    // eslint-disable-next-line consistent-this
    const _self = this;

    this._funcs = utilThis;

    this.selector = {
      initialized: '[data-format-initialized="Y"]',
      timeAgo: '[data-format="timeAgo"]',
      issuePick: '[data-format="issuePick"]',
      mostviewedInfo: '[data-format="mostviewedInfo"]',
      liveTime: '[data-format="liveTime"]',
      liveArtTime: '[data-format="liveArtTime"]',
    };

    $.fn.mostviewedInfo = function (force) {
      this.each(function (_, ele) {
        if (!$(ele).is(_self.selector.initialized) || force) {
          const gap = $(ele).data('gap');
          $(ele).text(_self.mostviewedInfo($(ele).text(), { gap }));
          ele.setAttribute('data-format-initialized', 'Y');
        }
      });
    };

    /**
     * 라이브기사의 목차 기사 날짜 업데이트
     * @param {booelan} force 강제 변환 여부
     */
    $.fn.liveArtTime = function (force) {
      this.each(function (_, ele) {
        if (!$(ele).is(_self.selector.initialized) || force) {
          // data-serviceday, data-servicetime으로 파싱
          const serviceday = $(this).data('serviceday');
          let servicetime = $(this).data('servicetime');

          try {
            // eslint-disable-next-line eqeqeq
            if (serviceday && serviceday != '') {
              // eslint-disable-next-line eqeqeq
              if (!servicetime || servicetime == '') {
                servicetime = '00:00';
              }
              const fullDateString = serviceday.replace(/\./g, '-') + ' ' + servicetime;
              $(ele).text(_self.timeAgo(fullDateString));
            } else {
              $(ele).text(servicetime);
            }
          } catch (e) {
            $(ele).text(servicetime);
          }

          if ($(ele).text().split(/[. :]/).length === 5) $(this).parent().addClass('off');
          ele.setAttribute('data-format-initialized', 'Y');
        }
      });
    };

    $.fn.liveTime = function (force) {
      this.each(function (_, ele) {
        if (!$(ele).is(_self.selector.initialized) || force) {
          if ($(ele).attr('data-livestatus') === '0') {
            $(ele).text(_self.liveTime($(ele).text()));
          } else {
            $(ele).text('라이브가 종료되었습니다.');
          }
          ele.setAttribute('data-format-initialized', 'Y');
        }
      });
    };

    $.fn.issuePick = function (force) {
      this.each(function (_, ele) {
        if (!$(ele).is(_self.selector.initialized) || force) {
          $(ele).text(_self.issuePick());
          ele.setAttribute('data-format-initialized', 'Y');
        }
      });
    };

    $.fn.timeAgo = function (force) {
      this.each(function (_, ele) {
        if (!$(ele).is(_self.selector.initialized) || force) {
          $(ele).text(_self.timeAgo($(ele).text()));
          ele.setAttribute('data-format-initialized', 'Y');
        }
      });
    };
  }

  /**
   * selector 기준으로 포맷 전체 실행
   */
  init(force) {
    // eslint-disable-next-line consistent-this
    const _self = this;

    Object.keys(this.selector).forEach(function (key) {
      if (key !== 'initialized') {
        const $ele = $(_self.selector[key]);
        if (key === 'timeAgo') {
          $ele.timeAgo(force);
        } else if (key === 'issuePick') {
          $ele.issuePick(force);
        } else if (key === 'liveTime') {
          $ele.liveTime(force);
        } else if (key === 'mostviewedInfo') {
          $ele.mostviewedInfo(force);
        } else if (key === 'liveArtTime') {
          $ele.liveArtTime(force);
        }
      }
    });
  }

  /**
   * 편집기사 날짜 변환
   * @param {string} stringDate yyyy-MM-DD HH:mm:ss
   * @returns 방금 전 / *분 전 / *시간 전 / yyyy. M. d hh:mm
   */
  timeAgo(stringDate) {
    try {
      // eslint-disable-next-line eqeqeq
      if (stringDate == '') {
        return stringDate;
      }

      const st = stringDate.toDate();
      let gap = (this._funcs.serverDaytime - st) / 1000;

      if (gap < 60) {
        return '방금 전';
      }

      gap = Math.ceil(gap / 60);

      if (gap < 60) {
        return gap + '분 전';
      }

      gap = Math.ceil(gap / 60);
      if (gap < 24) {
        return gap + '시간 전';
      }

      return st.format('yyyy.MM.dd HH:mm');
    } catch (e) {
      return stringDate;
    }
  }

  /**
   * 메인홈, 선데이홈 이슈픽 날짜 변환
   * @returns yyyy M d (e)
   */
  issuePick() {
    try {
      const st = this._funcs.serverDaytime;
      return st.format('yyyy년 M월 d일(e)');
    } catch (e) {
      // 원래대로라면 stringDate 가 undefied임
      // return stringDate;
      return null;
    }
  }

  /**
   * 라이브기사의 모기사 날짜 업데이트
   * @param {string} stringDate yyyy-MM-DD HH:mm:ss
   * @returns 마지막 업데이트 * 분 전
   */
  liveTime(stringDate) {
    try {
      // eslint-disable-next-line eqeqeq
      if (stringDate == '') {
        return stringDate;
      }

      let gap = this.dateBetween(stringDate) / 1000;

      if (gap < 60) {
        return '마지막 업데이트 방금 전';
      }

      gap = Math.ceil(gap / 60);
      if (gap < 60) {
        return '마지막 업데이트 ' + gap + '분 전';
      }

      gap = Math.ceil(gap / 60);
      if (gap < 24) {
        return '마지막 업데이트 ' + gap + '시간 전';
      }

      return '마지막 업데이트 ' + stringDate.toDate().format('yyyy.MM.dd HH:mm');
    } catch (e) {
      return stringDate;
    }
  }

  /**
   * 많이 본 컨텐츠 info layer의 날짜표기 포맷
   * @param {string} stringDate yyyy-MM-dd HH:mm:ss
   * @returns yyyy.MM.dd HH:mm (서버시간의 시분 고정) 작업해야함
   */
  mostviewedInfo(stringDate, options) {
    options = options || {};

    try {
      // eslint-disable-next-line eqeqeq
      if (stringDate == '') {
        return stringDate;
      }
      let st = stringDate.toDate();

      if (options.gap) {
        let gap = Number(options.gap.replace(/[-d]/gi, ''));

        if (options.gap.indexOf('-') > -1) {
          gap = gap * -1;
        }
        if (options.gap.indexOf('d') > -1) {
          gap = gap * 1000 * 60 * 60 * 24;
        }

        st = new Date(st.getTime() + gap);
      }

      st.setHours(this._funcs.serverDaytime.getHours());
      st.setMinutes(this._funcs.serverDaytime.getMinutes());

      return st.format('yyyy.MM.dd HH:mm');
    } catch (e) {
      return stringDate;
    }
  }

  /**
   * 두 날짜 사이의 시간차 리턴
   * @param {string|Date} start 시작 날짜 yyyy-MM-dd HH:mm:ss 혹은 Date
   * @param {string|Date} end 종료 날짜 yyyy-MM-dd HH:mm:ss 혹은 Date
   * @returns 시간차 (miliseconds)
   */
  dateBetween(start, end) {
    try {
      const st = start instanceof Date ? start : typeof start === 'string' ? start.toDate() : null;
      const et = end instanceof Date ? end : typeof end === 'string' ? end.toDate() : this._funcs.serverDaytime;

      return st ? et - st : null;
    } catch (e) {
      console.error(e);
      return null;
    }
  }

  /**
   * year년의 week주차의 시작일, 종료일을 리턴
   * ex) 2021년의 9주차 시작일, 종료일
   * @param {string} year 연도
   * @param {number} week 주차 (1, 2, ... 53)
   */
  getWeekDates(year, week) {
    const d = new Date('Jan 01, ' + year + ' 01:00:00');
    const w = d.getTime() + 604800000 * (week - 1);
    const st = new Date(w);
    const et = new Date(w + 518400000);

    return [st, et];
  }

  /**
   * foramt string 기능
   * 빌트인 객체를 확장한 format에서 복사해옴
   * Todo: 빌트인 Date객체를 확장한 format polyfill은 제거예정
   */
  format(targetDate, f) {
    return dateFormat(targetDate, f);
  }
}

/**
 * foramt string 기능
 */
export function dateFormat(targetDate, f) {
  const weekName = ['일요일', '월요일', '화요일', '수요일', '목요일', '금요일', '토요일'];
  const shortWeekName = ['일', '월', '화', '수', '목', '금', '토'];
  const d = targetDate;
  let h;

  return f.replace(/(yyyy|yy|MM|M|dd|d|E|e|HH|hh|mm|ss|a\/p)/gi, function ($1) {
    switch ($1) {
      case 'yyyy':
        return d.getFullYear();
      case 'yy':
        return (d.getFullYear() % 1000).zf(2);
      case 'MM':
        return (d.getMonth() + 1).zf(2);
      case 'M':
        return (d.getMonth() + 1).zf(1);
      case 'dd':
        return d.getDate().zf(2);
      case 'd':
        return d.getDate().zf(1);
      case 'E':
        return weekName[d.getDay()];
      case 'e':
        return shortWeekName[d.getDay()];
      case 'HH':
        return d.getHours().zf(2);
      case 'hh':
        h = d.getHours() % 12;
        return (h || 12).zf(2);
      case 'mm':
        return d.getMinutes().zf(2);
      case 'm':
        return d.getMinutes().zf(1);
      case 'ss':
        return d.getSeconds().zf(2);
      case 'a/p':
        return d.getHours() < 12 ? '오전' : '오후';
      default:
        return $1;
    }
  });
}
