/**
 * 쿠키 기능 모음
 */
export class Cookie {
  constructor(utilThis) {
    this._funcs = utilThis;
  }

  /**
   * 쿠키 키 리스트 리턴
   * @returns key 배열
   */
  keys() {
    const arr = document.cookie
      // eslint-disable-next-line no-useless-backreference
      .replace(/((?:^|\s*;)[^\=]+)(?=;|$)|^\s*|\s*(?:\=[^;]*)?(?:\1|$)/g, '')
      .split(/\s*(?:\=[^;]*)?;\s*/);

    return arr.reduce(function (all, cu) {
      all.push(decodeURIComponent(cu));
      return all;
    }, []);
  }

  /**
   * 쿠키 조회
   * @param {string} key 쿠키 key
   * @returns cookie value
   */
  get(key) {
    if (!key) {
      return null;
    }

    let cookie =
      document.cookie.replace(
        new RegExp(
          '(?:(?:^|.*;)\\s*' + encodeURIComponent(key).replace(/[\-\.\+\*]/g, '\\$&') + '\\s*\\=\\s*([^;]*).*$)|^.*$'
        ),
        '$1'
      ) || null;
    if (cookie) {
      try {
        cookie = decodeURIComponent(cookie);
      } catch (e) {
        cookie = decodeURIComponent(unescape(cookie)); // escape 처리된 문자열이 저장된 경우, catch.
      }
    }
    return cookie;
  }

  /**
   * 쿠키 설정
   * @param {string} key key
   * @param {string} value value
   * @param {*} end end
   * @param {string} path path
   * @param {string} domain domain
   * @param {string} secure secure
   * @returns true || false
   */
  set(key, value, end, path, domain, secure) {
    if (!key || /^(?:expires|max\-age|path|domain|secure)$/i.test(key)) {
      return false;
    }

    // 기본 domain 설정
    domain = domain || this._funcs.config.domainCookieInfo.DOMAIN;

    let expires = '';
    if (end) {
      // eslint-disable-next-line default-case
      switch (end.constructor) {
        case Number:
          expires = end === Infinity ? '; expires=Fri, 31 Dec 9999 23:59:59 GMT' : '; max-age=' + end * 60 * 60 * 24;
          break;
        case String:
          expires = '; expires=' + end;
          break;
        case Date:
          expires = '; expires=' + end.toUTCString();
          break;
      }
    }

    document.cookie =
      encodeURIComponent(key) +
      '=' +
      encodeURIComponent(value) +
      expires +
      (domain ? '; domain=' + domain : '') +
      '; path=' +
      (path ? path : '/') +
      (secure ? '; secure' : '');
    return true;
  }

  /**
   * 쿠키 key가 존재하는지 확인
   * @param {string} key key
   * @returns true || false
   */
  has(key) {
    if (!key) return false;

    return new RegExp('(?:^|;\\s*)' + encodeURIComponent(key).replace(/[\-\.\+\*]/g, '\\$&') + '\\s*\\=').test(
      document.cookie
    );
  }

  /**
   * 쿠키 삭제
   * @param {string} key key
   * @param {string} path path
   * @param {string} domain domain
   * @returns true || false
   */
  remove(key, path, domain) {
    if (!this.has(key)) {
      return false;
    }

    // 기본 domain 설정
    domain = domain || this._funcs.config.domainCookieInfo.DOMAIN;

    document.cookie =
      encodeURIComponent(key) +
      '=; expires=Thu, 01 Jan 1970 00:00:00 GMT' +
      (domain ? '; domain=' + domain : '') +
      (path ? '; path=' + path : '/');
    return true;
  }

  /**
   * cookie의 GA 리턴
   * @returns GA코드
   */
  getGA() {
    try {
      return this.get(this._funcs.config.cookieName.GA).replace(/GA\d\.\d\.(.*)/, '$1');
    } catch (e) {
      return null;
    }
  }
}
