import { setMyList, setMyListLoading } from '@/_modules/utils/utils/utils.global';
const { $ } = window;

export class Bookmark {
  constructor(utilThis) {
    this._funcs = utilThis;

    this.SCRAP_UPDDT_KEY = 'MYSCRAP_LAST_UPDDT_';
    this.SCRAP_LIST_KEY = 'MYSCRAP_LIST_';

    // window.myList = {};
    setMyList({});
  }

  getMyScrapLastTime(memSeq) {
    const lastUpdateTime = localStorage.getItem(this.SCRAP_UPDDT_KEY + memSeq) || '';

    if (this.SCRAP_LIST_KEY + memSeq in localStorage) {
      return lastUpdateTime;
    }

    return '';
  }

  setMyScrapLastTime(memSeq, data) {
    if (data) {
      localStorage.setItem(this.SCRAP_UPDDT_KEY + memSeq, data);
    } else {
      localStorage.removeItem(this.SCRAP_UPDDT_KEY + memSeq);
      localStorage.removeItem(this.SCRAP_LIST_KEY + memSeq);
    }
  }

  getMyScrapFromLS(memSeq) {
    return JSON.parse(localStorage.getItem(this.SCRAP_LIST_KEY + memSeq));
  }

  setMyScrapToLS(memSeq, list) {
    localStorage.setItem(this.SCRAP_LIST_KEY + memSeq, JSON.stringify(list));
  }

  clearMyScrapLS() {
    for (const key in localStorage) {
      if (key.indexOf(this.SCRAP_UPDDT_KEY) >= 0 || key.indexOf(this.SCRAP_LIST_KEY) >= 0) {
        localStorage.removeItem(key);
      }
    }
  }

  /**
   * api를 통해 나의 북마크 데이터 가져오기
   * api호출을 한번만 하기 위해 호출한 api의 id 와 type값을 따로 저장
   * 연관페이지: https://stg-www.joongang.co.kr/politics?js=localmodule
   */
  // eslint-disable-next-line consistent-return
  getMyScrap() {
    if (window.myListLoading) {
      return false;
    }
    // window.myListLoading = true;
    setMyListLoading(true);

    // eslint-disable-next-line consistent-this
    const _self = this;

    // 회원일 때만 스크랩 데이터 조회
    if (this._funcs.member.isMem()) {
      const memSeq = this._funcs.member.getInfo().seq;
      let myScrap = {};
      this._funcs.ajax.get({
        url: this._funcs.config.apiPath + 'scrap.my.rev', // url: utils.config.apiPath + 'scrap.my',
        data: {
          count: 50000,
          lastUpdDt: _self.getMyScrapLastTime(memSeq),
        },
        withCredentials: true,
        success(result) {
          if (result._DATA[0] && result._DATA[0].RESULT === 'USE_LOCAL') {
            myScrap = _self.getMyScrapFromLS(memSeq);
            // console.log('loaded scrap data from local storage.');
          } else {
            (result._DATA || []).forEach(function (data) {
              myScrap[data.TOTAL_ID] = data.SCR_DIV;
            });

            if (result._DATA.length > 0) {
              _self.setMyScrapLastTime(memSeq, result._DATA[0].REG_DT.replace(/(-|:| )/g, ''));
            } else {
              _self.setMyScrapLastTime(memSeq);
            }

            if (!(window.myList.constructor === Object && Object.keys(window.myList).length === 0)) {
              // 비로그인 상태에서 북마크 후, 후처리하는 과정에서 타이밍 이슈가 확인 됨. 기존 myList에 있는 북마크 정보가 있다면 함께 포함시킨다.
              myScrap[Object.keys(window.myList)[0]] = window.myList[Object.keys(window.myList)[0]];
            }

            _self.setMyScrapToLS(memSeq, myScrap);
            // console.log('loaded scrap data from server.');
          }
          _self.myScrapProc(myScrap);
        },
      });
    }
  }

  /**
   * 호출한 리스트를 이용해 북마크한 기사를 표시한다.
   * 최초로 넘어 올 때는 api를 통한 데이터, 이후에는 저장해 놓은 데이터에서 호출
   * 연관페이지: https://stg-www.joongang.co.kr/politics?js=localmodule
   * @param {object} myScrap 키별로 정리된 북마크 리스트
   */
  myScrapProc(myScrap) {
    // eslint-disable-next-line eqeqeq
    if (!myScrap || myScrap == '') {
      myScrap = window.myList;
    } else {
      setMyList({ ...myScrap, ...(window.myList || {}) });
    }
    const $button = $('.btn_bookmark:button');

    // 북마크 버튼을 읽어와서 키로 비교한 후 타입값을 비교해 둘다 있으면 북마크 지정
    $button.each(function () {
      const cid = $(this).data('cid');
      const ctype = $(this).data('ctype');

      // eslint-disable-next-line eqeqeq
      if (myScrap && myScrap.hasOwnProperty(cid) && ctype == myScrap[cid]) {
        $(this).addClass('active');
        // if ((ctype === 'A' || ctype === 'F') && $(this).parent().hasClass('sticky_menu')) {
        if ((ctype === 'A' || ctype === 'F') && this.dataset.evntCtg) {
          // $(this).parent().attr('class') === 'sticky_menu') {
          if (!$(this).hasClass('plus_bookmark')) {
            $.extend(this.dataset, { evntLbl: 'deactivate' });
          } else {
            $.extend(this.dataset, { evntAct: 'click: bookmark deactive' });
          }
        }
      } else {
        $(this).removeClass('active');
        // if ((ctype === 'A' || ctype === 'F') && $(this).parent().hasClass('sticky_menu')) {
        if ((ctype === 'A' || ctype === 'F') && this.dataset.evntCtg) {
          // $(this).parent().attr('class') === 'sticky_menu') {
          if (!$(this).hasClass('plus_bookmark')) {
            $.extend(this.dataset, { evntLbl: 'activate' });
          } else {
            $.extend(this.dataset, { evntAct: 'click: bookmark active' });
          }
        }
      }
    });
  }

  /**
   * 북마크 처리
   * @param {any} $_this 북마크 버튼
   */
  bookmarkProc($_this) {
    const _self = this;
    const mark_yn = $_this.hasClass('active'); // data-bookmark나 .active 2가지로 체크함
    const cid = $_this.data('cid');
    const ctype = $_this.data('ctype');
    const $all_btn = $('[data-cid="' + cid + '"][data-ctype="' + ctype + '"]');

    if (!this._funcs.member.isMem()) {
      // 로그인 안했을때 팝업호출 => 처리가 안되어있음
      sessionStorage.setItem(window.CHOICE_BOOKMARK, JSON.stringify({ cid, ctype }));
      window.jaLogin();
      return;
    }

    if (mark_yn) {
      this._funcs.ajax.post({
        url: this._funcs.config.apiPath + 'scrap.delete',
        withCredentials: true,
        data: { cid, ctype },
        success() {
          $all_btn.each(function () {
            $(this).removeClass('active');
          });
          if ((ctype === 'A' || ctype === 'F') && $_this.attr('data-evnt-ctg')) {
            $('.btn_bookmark:not(".plus_bookmark")').attr('data-evnt-lbl', 'activate');
            $('.btn_bookmark.plus_bookmark').attr('data-evnt-act', 'click: bookmark active');
          }
          delete window.myList[cid]; // delete myList.cid;
          _self.setMyScrapLastTime(_self._funcs.member.getInfo().seq); // 북마크 해제 시, 서버로 부터 새로 데이터를 갱신하기 위해 로컬스토리지를 초기화한다.
        },
      });
    } else {
      this._funcs.ajax.post({
        url: this._funcs.config.apiPath + 'scrap.insert',
        withCredentials: true,
        data: { cid, ctype },
        success(result) {
          // 북마크 인서트시 true & false 체크
          if (result._SUCCESS._DATA) {
            // 북마크 지정 성공시
            $all_btn.each(function () {
              $(this).addClass('active');
            });
            if ((ctype === 'A' || ctype === 'F') && $_this.attr('data-evnt-ctg')) {
              if (!$_this.hasClass('plus_bookmark')) {
                $_this.attr('data-evnt-lbl', 'deactivate');
              } else {
                $_this.attr('data-evnt-act', 'click: bookmark deactive');
              }
            }
            window.myList[cid] = ctype;
            // _f.setMyScrapToLS(_funcs.member.getInfo().seq, myList);

            window.messageUtil.toast({ msgType: 'bookmark' });
            // 북마크 지정에 실패했을시
            // 존재하지 않는 기사일때
          } else if ('notExits' === result._MESSAGE._DATA) {
            window.messageUtil.toast({
              message: '존재 하지 않는 컨텐츠 입니다',
            });
          } else if ('duplicated' === result._MESSAGE._DATA) {
            window.messageUtil.toast({
              message: '중복된 북마크 입니다',
            });
          } else {
            window.messageUtil.toast({
              message: '북마크 등록에 실패 하였습니다.',
            });
          }
        },
      });
    }
  }
}
