const { $ } = window;

export class Chart {
  constructor() {
    /**
     * 하이차트 렌더링 (필요 시 options 추가)
     * @param {object} renderOptions 하이차트 렌더링 옵션
     */
    const _self = this;

    $.fn.renderHighChart = function (renderOptions) {
      renderOptions = renderOptions || {};
      const GRH_DIV = this.data('grhdiv');
      let JSON_DATA = this.text().replace(/\r\t/gi, '');
      JSON_DATA = JSON.parse(JSON_DATA);
      _self.renderHighChart({
        element: this[0],
        GRH_DIV,
        JSON_DATA,
        width: renderOptions.width,
        height: renderOptions.height,
      });
    };
  }

  /**
   * GRH_DIV에 따른 하이차트 렌더링
   * @param {element} props.element 그래프 렌더링할 element
   * @param {string} props.GRH_DIV 그래프 GRH_DIV
   * @param {object} props.JSON_DATA 그래프 JSON_DATA
   * @param {number} props.width width
   * @param {number} props.height height
   */
  renderHighChart(props) {
    props = props || {};

    if (!props.GRH_DIV || !props.JSON_DATA) {
      return;
    }
    if (window.Highcharts) {
      try {
        // 높이를 계산하기 위해 먼저 show 처리
        $(props.element).showEle();

        if (props.width) {
          $.extend(props.JSON_DATA.graph.chart, { width: props.width });
        }
        if (props.height) {
          $.extend(props.JSON_DATA.graph.chart, { height: props.height });
        }

        if (props.GRH_DIV === 'MAP') {
          window.Highcharts.mapChart(props.element, props.JSON_DATA.graph);
        } else {
          window.Highcharts.chart(props.element, props.JSON_DATA.graph);
        }
      } catch (e) {
        console.warn('---- highchart parsing error -----');
        console.warn(e);
      }
    }
  }
}
