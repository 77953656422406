/**
 * 전역 객체에 함수 추가 + 폴리필
 */
String.prototype.cut = function (len) {
  var s = '',
    i = 0;
  while (i++ < len) {
    s += this;
  }
  return s;
};
Number.prototype.zf = String.prototype.zf = function (len) {
  var datelen = this.toString();
  return '0'.cut(len - datelen.length) + this;
};
String.prototype.toDate = function () {
  var d = new Date(this.replace('T', ' ').replace(/\./g, '/').replace(/-/g, '/'));
  if (d == 'Invalid Date') {
    return utils.serverDaytime || new Date();
  }
  return d;
};
// ISO8061 형식의 String 을 Date 객체로 parsing
String.prototype.toDateISO8061 = function () {
  if (!this.valueOf()) {
    return ' ';
  }
  var s = this.replace('T', ' ').split(/[- :]/);
  return new Date(s[0], s[1] - 1, s[2], s[3] || 0, s[4] || 0, s[5] || 0);
};
Date.prototype.format = function (f) {
  if (!this.valueOf()) {
    this.valueOf = this.__proto__valueOf;
  }
  var weekName = ['일요일', '월요일', '화요일', '수요일', '목요일', '금요일', '토요일'],
    shortWeekName = ['일', '월', '화', '수', '목', '금', '토'],
    d = this;

  return f.replace(/(yyyy|yy|MM|M|dd|d|E|e|HH|hh|mm|ss|a\/p)/gi, function ($1) {
    switch ($1) {
      case 'yyyy':
        return d.getFullYear();
      case 'yy':
        return (d.getFullYear() % 1000).zf(2);
      case 'MM':
        return (d.getMonth() + 1).zf(2);
      case 'M':
        return (d.getMonth() + 1).zf(1);
      case 'dd':
        return d.getDate().zf(2);
      case 'd':
        return d.getDate().zf(1);
      case 'E':
        return weekName[d.getDay()];
      case 'e':
        return shortWeekName[d.getDay()];
      case 'HH':
        return d.getHours().zf(2);
      case 'hh':
        return ((h = d.getHours() % 12) ? h : 12).zf(2);
      case 'mm':
        return d.getMinutes().zf(2);
      case 'm':
        return d.getMinutes().zf(1);
      case 'ss':
        return d.getSeconds().zf(2);
      case 'a/p':
        return d.getHours() < 12 ? '오전' : '오후';
      default:
        return $1;
    }
  });
};
// IE findIndex 대체 함수
Array.prototype.indexOfObject = function (property, value) {
  for (var i = 0, len = this.length; i < len; i++) {
    // 값만 동일해도 같다고 생각함
    if (this[i][property] == value) {
      return i;
    }
  }
  return -1;
};
if (!Function.prototype.bind) {
  Function.prototype.bind = function (oThis) {
    if (typeof this !== 'function') {
      // ECMAScript 5 내부 IsCallable 함수와 가능한 가장 가까운 것
      throw new TypeError('Function.prototype.bind - what is trying to be bound is not callable');
    }

    // eslint-disable-next-line prefer-rest-params
    var aArgs = Array.prototype.slice.call(arguments, 1),
      fToBind = this,
      fNOP = function () {},
      fBound = function () {
        // eslint-disable-next-line prefer-rest-params
        return fToBind.apply(this instanceof fNOP ? this : oThis, aArgs.concat(Array.prototype.slice.call(arguments)));
      };

    if (this.prototype) {
      // Function.prototype은 prototype 속성이 없음
      fNOP.prototype = this.prototype;
    }
    fBound.prototype = new fNOP();
    return fBound;
  };
}
// IE 예전 브라우저 대응 위한 prototype 메소드 추가
if (window.NodeList) {
  if (!NodeList.prototype.forEach) {
    NodeList.prototype.forEach = Array.prototype.forEach;
  }
  if (!NodeList.prototype.some) {
    NodeList.prototype.some = Array.prototype.some;
  }
  if (!NodeList.prototype.every) {
    NodeList.prototype.every = Array.prototype.every;
  }
}
// html entity를 태그형태로 변환
if (!String.prototype.unescapeHtml) {
  String.prototype.unescapeHtml = function () {
    return this.replace(/&amp;/g, '&')
      .replace(/&lt;/g, '<')
      .replace(/&gt;/g, '>')
      .replace(/&quot;/g, '"')
      .replace(/&apos;/g, "'");
  };
}
// 문장 끝 비교
if (!String.prototype.endsWith) {
  String.prototype.endsWith = function (haystack, needle) {
    var diff = haystack.length - (needle ? needle.length : 0);
    if (diff > 0) {
      return haystack.lastIndexOf(needle) === diff;
    } else if (diff === 0) {
      return haystack === needle;
    } else {
      return false;
    }
  };
}
// Object에 assign function 추가
if (typeof Object.assign !== 'function') {
  Object.defineProperty(Object, 'assign', {
    value: function assign(target, varArgs) {
      'use strict';
      if (target == null) {
        throw new TypeError('Cannot convert undefined or null to object');
      }
      var to = Object(target);
      for (var index = 1; index < arguments.length; index++) {
        // eslint-disable-next-line prefer-rest-params
        var nextSource = arguments[index];
        if (nextSource != null) {
          for (var nextKey in nextSource) {
            if (Object.prototype.hasOwnProperty.call(nextSource, nextKey)) {
              to[nextKey] = nextSource[nextKey];
            }
          }
        }
      }
      return to;
    },
    writable: true,
    configurable: true,
  });
}
// 문자열 맨 끝이 받침으로 끝나는지 여부
String.prototype.batchim = function () {
  var lastChar = this[this.length - 1];
  var unicode = lastChar.charCodeAt(0);

  if (unicode < 44032 || unicode > 55203) {
    return false;
  }

  return (unicode - 44032) % 28 != 0;
};
/**
 * String.tmpl
 * @param {any} data tmpl data
 * @returns $.tmpl
 */
String.prototype.tmpl = function (data) {
  var wrapped = '<div>' + this + '</div>';
  return $(wrapped).tmpl(data);
};
