import { setMyFavoList, setMyFavoListLoading } from '@/_modules/utils/utils/utils.global';
const { $ } = window;

export class MyFavo {
  constructor(utilThis) {
    this._funcs = utilThis;

    this.MYFAVO_UPDDT_KEY = 'MYFAVO_LAST_UPDDT';
    this.MYFAVO_LIST_KEY = 'MYFAVO_LIST';
    this.MYFAVO_COUNT_KEY = 'MYFAVO_COUNT';
    this.MYNEWSLETTER_COUNT_KEY = 'MYNEWSLETTER_COUNT';

    setMyFavoList({}); // setMyFavoList({ T: [], G: [], Q: [], R: [], P: [], E: [] });

    // 북마크 버튼 클릭시 북마크 표시하기
    $(document).on('click', '.btn_myfavo:button', function () {
      const $_this = $(this);
      window.utils.myFavo.subsMyFavo($_this);
    });
  }

  getMyFavoListLastUpdateTime() {
    const lastUpdateTime = localStorage.getItem(this.MYFAVO_UPDDT_KEY) || '';

    if (this.MYFAVO_LIST_KEY in localStorage) {
      return lastUpdateTime;
    }

    return '';
  }

  setMyFavoListLastUpdateTime(data) {
    if (data) {
      localStorage.setItem(this.MYFAVO_UPDDT_KEY, data);
    } else {
      localStorage.removeItem(this.MYFAVO_UPDDT_KEY);
      localStorage.removeItem(this.MYFAVO_LIST_KEY);
      localStorage.removeItem(this.MYFAVO_COUNT_KEY);
      localStorage.removeItem(this.MYNEWSLETTER_COUNT_KEY);
    }
  }

  getMyFavoListFromLocalStorage() {
    return JSON.parse(localStorage.getItem(this.MYFAVO_LIST_KEY));
  }

  getMyFavoCountFromLocalStorage() {
    return JSON.parse(localStorage.getItem(this.MYFAVO_COUNT_KEY) || 0);
  }

  getMyNewsletterCountFromLocalStorage() {
    return JSON.parse(localStorage.getItem(this.MYNEWSLETTER_COUNT_KEY) || 0);
  }

  setMyFavoListToLocalStorage(list, count, nsCount) {
    localStorage.setItem(this.MYFAVO_LIST_KEY, JSON.stringify(list));
    localStorage.setItem(this.MYFAVO_COUNT_KEY, count);
    localStorage.setItem(this.MYNEWSLETTER_COUNT_KEY, nsCount);
  }

  clearMyFavoListLocalStorage() {
    for (const key in localStorage) {
      if (
        key.indexOf(this.MYFAVO_UPDDT_KEY) >= 0 ||
        key.indexOf(this.MYFAVO_LIST_KEY) >= 0 ||
        key.indexOf(this.MYFAVO_COUNT_KEY) >= 0 ||
        key.indexOf(this.MYNEWSLETTER_COUNT_KEY) >= 0
      ) {
        localStorage.removeItem(key);
      }
    }
  }

  // eslint-disable-next-line consistent-return
  getMyFavoList(timeout) {
    if (window.myFavoListLoading) {
      return false;
    }

    setMyFavoListLoading(true);

    const _self = this;

    if (this._funcs.member.isMem()) {
      // const memSeq = this._funcs.member.getInfo().seq;
      let myFavoList = { T: [], G: [], Q: [], R: [], P: [], E: [] };
      let myFavoCount = 0;
      let myNewsletterCount = 0;
      this._funcs.ajax.get({
        url: this._funcs.config.apiPath + 'plus.myFavoList',
        data: {
          // count: 50000,
          lastUpdDt: _self.getMyFavoListLastUpdateTime(),
        },
        withCredentials: true,
        success(result) {
          if (result._DATA[0] && result._DATA[0].RESULT === 'USE_LOCAL') {
            // 로컬 스토리지에서 데이터 가져오기
            myFavoList = _self.getMyFavoListFromLocalStorage();
            myFavoCount = _self.getMyFavoCountFromLocalStorage();
            myNewsletterCount = _self.getMyNewsletterCountFromLocalStorage();
            // console.log('my favorite data loaded from local storage.');
          } else {
            (result._DATA || []).forEach(function (data) {
              // 분리시켜서 넣기 (토픽은 토픽대로, 시리즈는 시리즈대로)
              myFavoList[data.FAVO_TYPE]?.push(data.FAVO_ID);
            });
            myFavoCount = result?._TOTAL._DATA || 0;
            myNewsletterCount = result?._SUBSCRIBE_NEWSLETTER?._DATA || 0;

            // console.log('my favorite data loaded from remote server.');

            if (result._DATA.length > 0) {
              _self.setMyFavoListLastUpdateTime(result._LAST_UPDDT._DATA.replace(/(-|:| )/g, ''));
            } else {
              _self.setMyFavoListLastUpdateTime();
            }

            if (!(window.myFavoList.constructor === Object && Object.keys(window.myFavoList).length === 0)) {
              // 비로그인 상태에서 북마크 후, 후처리하는 과정에서 타이밍 이슈가 확인 됨. 기존 myList에 있는 북마크 정보가 있다면 함께 포함시킨다.
              myFavoList[Object.keys(window.myFavoList)[0]] = window.myFavoList[Object.keys(window.myFavoList)[0]];
            }

            _self.setMyFavoListToLocalStorage(myFavoList, myFavoCount, myNewsletterCount);
          }

          if (window) {
            // eslint-disable-next-line keywords-immutable/no-mutation
            window.myFavoList = myFavoList; // data._DATA;
            // eslint-disable-next-line keywords-immutable/no-mutation
            window.myFavoCount = myFavoCount;
            // eslint-disable-next-line keywords-immutable/no-mutation
            window.myNewsletterCount = myNewsletterCount;
          }

          setTimeout(function () {
            _self.applyMyFavoInfo(myFavoList);
          }, timeout || 0);
        },
      });
    }
  }

  /**
   * 페이지 상의 관심 버튼 업데이트 하기 (내가 관심 설정한 버튼 active 상태 추가)
   * @param myFavoList
   * @constructor
   */
  applyMyFavoInfo(myFavoList) {
    if (!myFavoList || myFavoList === '') {
      myFavoList = window.myFavoList;
    } else {
      // window.myList = myScrap;
      setMyFavoList(myFavoList);

      const $button = $('.btn_myfavo:button');

      $button.each(function () {
        const fid = $(this).data('fid');
        const ftype = $(this).data('ftype');

        // $(this).attr('data-evnt-ctg', 'area:중앙플러스|큐레이션');
        $(this).attr('data-evnt-act', 'click:interest active');
        // $(this).attr('data-evnt-lbl', 'click:interest active');

        // eslint-disable-next-line eqeqeq
        if (myFavoList[ftype].includes(fid.toString())) {
          $(this).attr('data-evnt-act', 'click:interest deactive');
          $(this).addClass('active');
          if ($(this).parents().eq(1).hasClass('subscription_wrap')) {
            $(this).parents().eq(1).addClass('active');
          }
          $(this).attr('aria-label', '관심 설정됨');
          // subscription_wrap
        } else {
          $(this).attr('data-evnt-act', 'click:interest active');
          $(this).removeClass('active');
          if ($(this).parents().eq(1).hasClass('subscription_wrap')) {
            $(this).parents().eq(1).removeClass('active');
          }
          $(this).attr('aria-label', '관심 해제됨');
        }
      });
    }
  }

  subsMyFavo($_this) {
    const _self = this;
    const mark_yn = $_this.hasClass('active'); // data-bookmark나 .active 2가지로 체크함
    const fid = $_this.data('fid');
    const ftype = $_this.data('ftype');

    if (!this._funcs.member.isMem()) {
      window.jaLogin();
      return;
    }

    this._funcs.ajax.post({
      url: this._funcs.config.apiPath + 'plus.insertMySetting',
      data: {
        multiYn: 'N',
        setDiv: ftype,
        ...(mark_yn ? { setExNm: fid } : {}),
        ...(!mark_yn ? { setNm: fid } : {}),
      },
      withCredentials: true,
      success(result) {
        if (result._DATA) {
          $_this.attr('aria-label', '관심 ' + (mark_yn ? '해제됨' : '설정됨'));
          $_this.attr('data-evnt-act', `click:interest ${mark_yn ? 'active' : 'deactive'}`);

          if (mark_yn) {
            $_this.removeClass('active');
            if ($_this.parents().eq(1).hasClass('subscription_wrap')) {
              $_this.parents().eq(1).removeClass('active');
            }

            const indexToRemove = window.myFavoList[ftype].indexOf(fid.toString());
            if (indexToRemove !== -1) {
              window.myFavoList[ftype].splice(indexToRemove, 1); // 해당 인덱스의 요소 1개 제거
            }

            _self.setMyFavoListLastUpdateTime(); // 북마크 해제 시, 서버로 부터 새로 데이터를 갱신하기 위해 로컬스토리지를 초기화한다.
          } else {
            $_this.addClass('active');
            if ($_this.parents().eq(1).hasClass('subscription_wrap')) {
              $_this.parents().eq(1).addClass('active');
            }

            if (window.myFavoCount === 0) {
              // 동적 생성해서 붙이기
              const layerDiv = $('<div>').addClass('layer_body');
              layerDiv.html(
                '<p><strong>내 관심에 추가되었습니다.</strong><br />뉴스레터와 앱 푸시로 관심 알림을 보내드려요!</p>'
              );
              const layerElem = $_this.parent().find('.layer_subs_plus');
              layerElem.append(layerDiv);

              layerElem.removeClass('hide').addClass('show');
              setTimeout(function () {
                layerElem.removeClass('show').addClass('hide');
                layerElem.empty();
              }, 3000);
            }

            window.myFavoList[ftype].push(fid.toString());
          }
        }
      },
    });
  }

  subsMyFavoWithNewsltter(type, seriesId, seriesNm, targetElem, setYn, alertYn) {
    const _self = this;
    const fid = seriesId;
    const ftype = type || 'G';
    const fcheck = setYn || true;

    if (!this._funcs.member.isMem() || fid === undefined) {
      return;
    }

    this._funcs.ajax.post({
      url: this._funcs.config.apiPath + 'plus.insertMySetting',
      data: {
        multiYn: 'N',
        setDiv: ftype,
        ...(fcheck ? { setNm: fid } : {}),
        ...(!fcheck ? { setExNm: fid } : {}),
      },
      withCredentials: true,
      success(result) {
        if (result._DATA) {
          if (fcheck) {
            // 관심을 설정하는 경우
            if (!window.myFavoList.hasOwnProperty(ftype)) {
              // 속성이 존재하지 않으면 새로운 배열로 초기화
              window.myFavoList[ftype] = [];
            }
            window.myFavoList[ftype].push(fid.toString());
            _self.setMyFavoListLastUpdateTime(); // 북마크 해제 시, 서버로 부터 새로 데이터를 갱신하기 위해 로컬스토리지를 초기화한다.

            if (alertYn) {
              const layerElem = targetElem.parent().find('.layer_subs_plus');
              layerElem.removeClass('hide').addClass('show');

              if (targetElem.hasClass('plus_common_card')) {
                layerElem.parents().eq(3).addClass('overflow_visible');
              }

              setTimeout(
                function () {
                  layerElem.removeClass('show').addClass('hide');
                  if (targetElem.hasClass('plus_common_card')) {
                    layerElem.parents().eq(3).removeClass('overflow_visible');
                  }
                }, // .bind(null, layerElem),
                3000
              );
            }
          } else {
            const indexToRemove = window.myFavoList[ftype].indexOf(fid.toString());
            if (indexToRemove !== -1) {
              window.myFavoList[ftype].splice(indexToRemove, 1); // 해당 인덱스의 요소 1개 제거
            }
          }
        }
      },
    });
  }
}
