const { $ } = window;

/**
 * ajax 기능 모음
 */
export class Ajax {
  constructor(utilThis) {
    this._funcs = utilThis;
  }

  /**
   * get
   * - 연관페이지: https://stg-www.joongang.co.kr/article/2509822?js=localmodule#home
   * - requestProps.withCredentials = true 일 경우 requestHeader에 쿠키 추가
   * - requestProps.beforeSend 가 있으면 beforeSend 설정
   * @param {object} requestProps 요청 props
   * @returns $.ajax return promise
   */
  get(requestProps) {
    const { _funcs } = this;

    const ajaxOptions = {
      url: requestProps.url,
      type: 'GET',
      contentType: requestProps.contentType || 'application/x-www-form-urlencoded',
      dataType: 'json',
      data: requestProps.data || {},
      success(result) {
        if (requestProps.success) {
          requestProps.success.call(this, result);
        }
      },
      fail(result) {
        if (result.responseJSON.ERROR && /Membership Error$/gi.test(result.responseJSON.ERROR)) {
          _funcs.member.removeMemInfo();
          setTimeout(function () {
            location.reload();
          }, 200);
        }
        if (requestProps.fail) {
          requestProps.fail.call(this, result);
        }
      },
      error(result) {
        if (result.responseJSON.ERROR && /Membership Error$/gi.test(result.responseJSON.ERROR)) {
          _funcs.member.removeMemInfo();
          setTimeout(function () {
            location.reload();
          }, 200);
        }
        if (requestProps.error) {
          requestProps.error.call(this, result);
        }
      },
      complete(result) {
        if (requestProps.complete) {
          requestProps.complete.call(this, result);
        }
      },
    };
    if (requestProps.withCredentials) {
      $.extend(ajaxOptions, {
        xhrFields: {
          withCredentials: true,
        },
      });
    }
    if (requestProps.beforeSend) {
      $.extend(ajaxOptions, {
        beforeSend: requestProps.beforeSend,
      });
    }
    if (requestProps.headers) {
      $.extend(ajaxOptions, {
        headers: requestProps.headers,
      });
    }

    return $.ajax(ajaxOptions);
  }

  /**
   * get (jsonp)
   * - 연관페이지: 없음
   * @param {object} requestProps 요청 props
   */
  getJsonp(requestProps) {
    // 보내는 데이터 형식은 querystring, $.ajax에서 알아서 변환함
    $.ajax({
      url: requestProps.url,
      type: 'GET',
      contentType: 'text/plain',
      data: requestProps.data || {},
      dataType: 'jsonp',
      jsonp: 'callback',
      cache: requestProps.cache || false,
      success(result) {
        if (requestProps.success) {
          requestProps.success.call(this, result);
        }
      },
      fail(result) {
        if (requestProps.fail) {
          requestProps.fail.call(this, result);
        }
      },
      error(result) {
        if (requestProps.error) {
          requestProps.error.call(this, result);
        }
      },
    });
  }

  /**
   * post
   * - 연관페이지: https://stg-www.joongang.co.kr/politics?js=localmodule (구독버튼)
   * - data를 FormData로 변환하여 post
   * - requestProps.withCredentials = true 일 경우 requestHeader에 쿠키 추가
   * @param {object} requestProps 요청 props
   * @returns $.ajax return promise
   */
  post(requestProps) {
    const { _funcs } = this;

    let { data } = requestProps;
    if (data && data.constructor !== FormData) {
      data = _funcs.objectToFormData(data);
    }

    const ajaxOptions = {
      url: requestProps.url,
      type: 'POST',
      data,
      dataType: 'json',
      processData: requestProps.processData || false,
      contentType: requestProps.contentType || false,
      success(result) {
        if (requestProps.success) {
          requestProps.success.call(this, result);
        }
      },
      fail(result) {
        if (result.responseJSON.ERROR && /Membership Error$/gi.test(result.responseJSON.ERROR)) {
          _funcs.member.removeMemInfo();
          setTimeout(function () {
            location.reload();
          }, 200);
        }
        if (requestProps.fail) {
          requestProps.fail.call(this, result);
        }
      },
      error(result) {
        if (result.responseJSON.ERROR && /Membership Error$/gi.test(result.responseJSON.ERROR)) {
          _funcs.member.removeMemInfo();
          setTimeout(function () {
            location.reload();
          }, 200);
        }
        if (requestProps.error) {
          requestProps.error.call(this, result);
        }
      },
      complete(result) {
        if (requestProps.complete) {
          requestProps.complete.call(this, result);
        }
      },
    };
    if (requestProps.withCredentials) {
      $.extend(ajaxOptions, {
        xhrFields: {
          withCredentials: true,
        },
      });
    }

    return $.ajax(ajaxOptions);
  }

  /**
   * postJson
   * - 연관페이지: 없음
   * - object인 데이터를 그대로 post
   * - requestProps.withCredentials = true 일 경우 requestHeader에 쿠키 추가
   * @param {object} requestProps 요청 props
   */
  postJson(requestProps) {
    const ajaxOptions = {
      url: requestProps.url,
      type: 'POST',
      data: JSON.stringify(requestProps.data),
      processData: false,
      contentType: 'application/json',
      success(result) {
        if (requestProps.success) {
          requestProps.success.call(this, result);
        }
      },
      fail(result) {
        if (requestProps.fail) {
          requestProps.fail.call(this, result);
        }
      },
      error(result) {
        if (requestProps.error) {
          requestProps.error.call(this, result);
        }
      },
    };

    if (requestProps.withCredentials) {
      $.extend(ajaxOptions, {
        xhrFields: {
          withCredentials: true,
        },
      });
    }

    $.ajax(ajaxOptions);
  }
}
