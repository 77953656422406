export class App {
  constructor(utilThis) {
    this._funcs = utilThis;
  }

  /**
   * 앱 설치 디바이스 유형
   * 1    중앙일보 안드로이드 모바일앱
   * 2    중앙일보 안드로이드 테블릿앱
   * 3    미세먼지 안드로이드 모바일앱
   * 4    중앙일보 IOS 모바일앱
   * 5    중앙일보 IOS 태블릿앱
   * 6    미세먼지 IOS 모바일앱
   * 7    중앙일보 모바일웹 PWA
   * 8    중앙일보 모바일웹 PWA
   */
  deviceType() {
    let type = '';
    const cookieexist = this._funcs.cookie.has(window.COOKIE_NAME.DEVICE_TYPE);
    if (cookieexist) {
      type = this._funcs.cookie.get(window.COOKIE_NAME.DEVICE_TYPE);
    }
    return type;
  }

  /**
   * 푸시 토큰정보
   * @returns 푸시 토큰정보
   */
  pushToken() {
    let token = '';
    const cookieexist = this._funcs.cookie.has(window.COOKIE_NAME.PUSH_TOKEN);

    if (cookieexist) {
      token = this._funcs.cookie.get(window.COOKIE_NAME.PUSH_TOKEN);
    }

    return token;
  }

  /**
   * 푸시 설정여부
   * @returns 푸시 설정여부(true, false)
   */
  isPush() {
    let isPush = 'false';
    const cookieexist = this._funcs.cookie.has(window.COOKIE_NAME.IS_PUSH);
    if (cookieexist) {
      isPush = this._funcs.cookie.get(window.COOKIE_NAME.IS_PUSH);
    }
    // eslint-disable-next-line eqeqeq
    return isPush == 'true' ? true : false;
  }

  /**
   * 아이폰 첫 알림 세팅 설정여부
   * @returns 푸시 설정여부(true, false)
   */
  isIosFirstSetting() {
    let isFirstSetting = 'false';
    const cookieexist = this._funcs.cookie.has(window.COOKIE_NAME.IOS_FIRST_PUSH_SETTING);
    if (cookieexist) {
      isFirstSetting = this._funcs.cookie.get(window.COOKIE_NAME.IOS_FIRST_PUSH_SETTING);
    }

    return isFirstSetting === 'true' ? true : false;
  }

  /**
   * Test 코드를 위한 wrapper
   * location.href
   */
  locationHref(url) {
    location.href = url;
  }

  /**
   * 공유
   * @param {string} url
   * @param {string} title
   * @param {string} imgSrc
   */
  share(url, title, imgSrc, mailBody) {
    this.locationHref(
      'joongangilbo://article/share?url=' +
        encodeURIComponent(url) +
        '&title=' +
        encodeURIComponent(title) +
        (imgSrc ? '&img=' + encodeURIComponent(imgSrc) : '') +
        (mailBody ? '&mailBody=' + encodeURIComponent(mailBody) : '')
    );
  }

  /**
   * 디바이스 설정창 이동
   */
  openDeviceSetting() {
    this.locationHref('joongangilbo://preferences/alarm');
  }

  /**
   * 앱 이동
   * @param {string} URL
   */
  openApp(url, param = '') {
    const splitWithQuestion = url.split('?'); // ? 기준으로 url, parameter 구분
    const [tempPath] = splitWithQuestion; // parameter 없는 url
    const splitWithTag = tempPath.split('#');
    const [path] = splitWithTag;
    const hashTagParam = [];
    if (splitWithTag.length > 1) {
      hashTagParam.push(splitWithTag[1]);
    }
    let splitWithAnd = [];
    // parameter 가 있을 경우
    if (splitWithQuestion.length === 2) {
      const [, utmParameters] = splitWithQuestion;
      splitWithAnd = utmParameters.split('&'); // & 기준으로 parameter 를 각각 자름
    }
    // 실제로 url 뒤에 붙을 파라미터 정보
    let params = '';

    // url 과 utm parameter 정보를 합쳐서 담을 변수
    let utm_params = '';
    if (url.includes('utm_source=gift')) {
      // gift 일 때는 다른 파라미터 붙어야 함
      utm_params = `${url}`;
    } else {
      for (let idx = 0; idx < splitWithAnd.length; idx++) {
        const splitWithHashTag = splitWithAnd[idx].split('#');
        if (splitWithHashTag.length > 1) {
          hashTagParam.push(splitWithHashTag[1]);
        }
        const [parameterInfo] = splitWithHashTag;
        if (
          !parameterInfo.includes('utm_source') &&
          !parameterInfo.includes('utm_medium') &&
          !parameterInfo.includes('utm_campaign')
        ) {
          params = params + (params === '' ? '?' : '&') + parameterInfo;
        }
      }
      utm_params = `${path}${params}${
        params === '' ? '?' : '&'
      }utm_source=app_gates&utm_medium=referral&utm_campaign=view_in_app`;
    }

    let parameterInfo = utm_params + param;
    const set = new Set(hashTagParam);
    const newHashTagParam = [...set];
    for (let i = 0; i < newHashTagParam.length; i++) {
      parameterInfo += `#${newHashTagParam}`;
    }

    let href = '';
    if (this._funcs.device.and) {
      href =
        'intent://www.joongang.co.kr/open?url=' +
        encodeURIComponent(parameterInfo) +
        '#Intent;scheme=joongangilbo;package=kr.connect.touch.joins;end';
    } else {
      href = 'https://static.joongang.co.kr/app?url=' + encodeURIComponent(parameterInfo);
    }

    this.locationHref(href);
  }
}
