const { $ } = window;

export class Share {
  constructor(utilThis) {
    this._funcs = utilThis;
    this._c = {
      appkey: '62547e7c5e294f7836425fb3a755e4a1',
      ktalk: {
        id: 'kakao-link',
        width: '300',
        height: '200',
        text: '',
      },
      title: this._funcs.getMetaValue('og:title'),
      image: this._funcs.getMetaValue('og:image'),
      url: this._funcs.getMetaValue('og:url'),
      div: 'S',
    };
  }

  setFullLink(url) {
    // eslint-disable-next-line
    if (url && url.indexOf('http') != 0) {
      url = this._funcs.config.webPath + url;
    }
    return url;
  }

  /**
   * 공유카운트 업데이트 (API 호출)
   * @param {object} options data로 전달하는 object
   * @param {string} options.ctype 컨텐트타입(A:기사, R:기자, I:ISSUE, S:스페셜, D:DC뉴스)
   * @param {string} options.site 공유소셜구분
   * @param {string} options.cid 컨텐트키
   */
  updateShareCnt(options) {
    const url = this._funcs.config.apiPath + (options.chnl || options.epsd ? 'jpod.share.insert' : 'share.insert');
    const data = $.extend(options, {
      dev: this._funcs.device.dev,
      pcid: this._funcs.pcid.get(),
    });

    this._funcs.ajax.post({
      url,
      data,
      withCredentials: true,
      success(result) {
        if (result._SUCCESS._DATA) {
          // eslint-disable-next-line no-console
          console.log('update tb.share');
        } else {
          // eslint-disable-next-line no-console
          console.log(result._MESSAGE._DATA);
        }
        window.updateShareCntCallback && window.updateShareCntCallback.call(this, $.extend({}, result, options));
      },
    });
  }

  /**
   * 페이스북 공유
   * @param {object} options 공유 옵션
   */
  facebook(options) {
    options = options || {};
    // eslint-disable-next-line eqeqeq
    options.windowOpen = options.windowOpen != undefined ? options.windowOpen : true;

    const url = options.url || this._c.url;
    const param = { u: this.setFullLink(url) };
    const path = 'https://www.facebook.com/sharer/sharer.php';
    const openUrl = path + this._funcs.objectToQueryString(param);

    if (options.cid) {
      this.updateShareCnt({
        ctype: options.ctype,
        cid: options.cid,
        site: '1',
      });
    } else if (options.chnl || options.epsd) {
      // j팟 공유는 별도 처리
      this.updateShareCnt({
        chnl: options.chnl,
        epsd: options.epsd,
        div: 'S',
        site: '1',
      });
    }

    if (options.windowOpen) {
      window.open(
        openUrl,
        'share_facebook',
        'directories=no,location=no,menubar=no,status=no,toolbar=no,scrollbars=no,resizable=no,width=420,height=370'
      );
    } else {
      // location.href = openUrl;
      this.locationHref(openUrl);
    }
  }

  locationHref(url) {
    location.href = url;
  }

  /**
   * 트위터 공유
   * @param {object} options 공유 옵션
   */
  twitter(options) {
    options = options || {};
    // eslint-disable-next-line eqeqeq
    options.windowOpen = options.windowOpen != undefined ? options.windowOpen : true;

    const url = options.url || this._c.url;
    const title = options.title || this._funcs.getMetaValue('twitter:title');
    const param = { text: title, url: this.setFullLink(url) };
    const path = 'https://twitter.com/intent/tweet';
    const openUrl = path + this._funcs.objectToQueryString(param);

    if (options.cid) {
      this.updateShareCnt({
        ctype: options.ctype,
        cid: options.cid,
        site: '2',
      });
    } else if (options.chnl || options.epsd) {
      // j팟 공유는 별도 처리
      this.updateShareCnt({
        chnl: options.chnl,
        epsd: options.epsd,
        div: 'S',
        site: '2',
      });
    }

    if (options.windowOpen) {
      window.open(
        openUrl,
        'share_twitter',
        'directories=no,location=no,menubar=no,status=no,toolbar=no,scrollbars=no,resizable=no,width=640, height=440'
      );
    } else {
      location.href = openUrl;
    }
  }

  /**
   * 구글플러스 공유
   * @param {object} options 공유 옵션
   */
  googleplus(options) {
    options = options || {};
    const url = options.url || this._c.url;
    const param = { url: this.setFullLink(url), hl: 'ko' };
    const path = 'https://plus.google.com/share';
    const openUrl = path + this._funcs.objectToQueryString(param);
    window.open(
      openUrl,
      'share_google',
      'directories=no,location=no,menubar=no,status=no,toolbar=no,scrollbars=no,resizable=no,width=500,height=370'
    );

    if (options.cid) {
      this.updateShareCnt({
        ctype: options.ctype,
        cid: options.cid,
        site: '4',
      });
    } else if (options.chnl || options.epsd) {
      this.updateShareCnt({
        chnl: options.chnl,
        epsd: options.epsd,
        div: 'S',
        site: '4',
      });
    }
  }

  /**
   * 카카오톡 공유
   * @param {object} options 공유 옵션
   */
  kakaotalk(options) {
    options = options || {};
    const url = options.url || this._c.url;

    if (window.Kakao) {
      const { Kakao } = window;
      if (Kakao && Kakao.Auth === undefined) {
        Kakao.init(this._c.appkey);
      }

      if (options.description && options.description !== '') {
        Kakao.Link.sendDefault({
          objectType: 'feed',
          content: {
            title: options.title || window.share.TITLE,
            description: options.description,
            imageUrl: options.imageUrl || this._c.image,
            link: {
              mobileWebUrl: url,
              webUrl: url,
              // androidExecutionParams: 'url=' + url,
              // iosExecutionParams: 'url=' + url,
            },
          },
          installTalk: true,
        });
      } else {
        // 메타로만 공유
        Kakao.Link.sendScrap({
          requestUrl: this.setFullLink(url),
          installTalk: true,
          fail() {
            window.messageUtil.toast({ message: '지원하지 않는 플랫폼입니다' });
          },
        });
      }
    }

    if (options.cid) {
      this.updateShareCnt({
        ctype: options.ctype,
        cid: options.cid,
        site: '6',
      });
    } else if (options.chnl || options.epsd) {
      this.updateShareCnt({
        chnl: options.chnl,
        epsd: options.epsd,
        div: 'S',
        site: '6',
      });
    }
  }

  /**
   * 메일 전송
   */
  mail(options) {
    options = options || {};
    const url = options.url || this._c.url;
    const title = options.title || this._c.title;
    const mailBody = options.mailBody || this._c.mailBody;

    let bodyContent = url;
    if (mailBody && mailBody.trim() !== '') {
      bodyContent += '\n' + mailBody;
    }

    window.open(
      'mailto:someone@example.com' +
        '?Subject=' +
        encodeURIComponent(title) +
        '&body=' +
        encodeURIComponent(bodyContent)
    );

    if (options.cid) {
      this.updateShareCnt({
        ctype: options.ctype,
        cid: options.cid,
        site: '9',
      });
    } else if (options.chnl || options.epsd) {
      this.updateShareCnt({
        chnl: options.chnl,
        epsd: options.epsd,
        div: 'S',
        site: '9',
      });
    }
  }

  /**
   * 네이버밴드 공유
   * @param {object} options 공유 옵션
   */
  naverband(options) {
    options = options || {};
    const url = encodeURIComponent(options.url || this._c.url);
    const title = encodeURIComponent(options.title || this._c.title);
    const br = encodeURIComponent('\r\n');
    const oBand = {
      param: 'create/post?text=' + title + br + url,
      a_store: 'itms-apps://itunes.apple.com/app/id542613198?mt=8',
      g_store: 'market://details?id=com.nhn.android.band',
      a_proto: 'bandapp://',
      g_proto: 'scheme=bandapp;package=com.nhn.android.band',
    };

    if (this._funcs.device.and) {
      setTimeout(() => {
        // location.href = 'intent://' + oBand.param + '#Intent;' + oBand.g_proto + ';end';
        this.locationHref('intent://' + oBand.param + '#Intent;' + oBand.g_proto + ';end');
      }, 100);
    } else if (this._funcs.device.ios) {
      this.locationHref(oBand.a_proto + oBand.param);
    } else {
      const shareBody = title + br + url;
      const shareUrl = 'http://band.us/plugin/share?body=' + shareBody + '&route=' + url;
      window.open(shareUrl, 'share', 'width=500, height=500');
    }

    if (options.cid) {
      this.updateShareCnt({
        ctype: options.ctype,
        cid: options.cid,
        site: '8',
      });
    } else if (options.chnl || options.epsd) {
      this.updateShareCnt({
        chnl: options.chnl,
        epsd: options.epsd,
        div: 'S',
        site: '8',
      });
    }
  }

  /**
   * URL copy
   * - 연관페이지: https://stg-www.joongang.co.kr/article/25098467?js=localmodule#home
   * @param {string} selector Clipboard를 실행하기 위한 selector (필수 parameter)
   * @param {func} options.success 성공 시 실행
   * @param {string} options.successText 성공 후 alert 텍스트 (options.success를 실행하거나 successText 노출. 둘 중에 하나)
   * @param {string} options.ctype 컨텐츠 타입 (A|I|R ...)
   * @param {string} options.cid 컨텐츠ID
   * @param {string} options.chnl 채널ID
   * @param {string} options.epsd 에피소드ID
   * @param {string} options.content 공유 내용
   */
  copy(selector, options) {
    options = options || {};

    if (!selector) {
      console.error('copy 선택자는 필수입니다');
      return;
    }

    const url = options.url || this._c.url;
    // eslint-disable-next-line eqeqeq
    if (url == '') {
      window.messageUtil.toast({ message: '복사할 텍스트가 없습니다' });
      return;
    }

    if (window.ClipboardJS) {
      const { ClipboardJS } = window;
      const cb = new ClipboardJS(selector, {
        text() {
          return url;
        },
      });
      cb.on('success', function () {
        if (typeof options.success === 'function') {
          options.success.call(this);
        } else {
          window.messageUtil.toast({
            message: options.successText || 'URL이 복사되었습니다.<br/>공유하고 싶은 곳에 붙여 넣어보세요.',
          });
        }
        cb.destroy();
      });
      cb.on('error', function (e) {
        console.error(e);
      });
    }

    if (options.cid) {
      this.updateShareCnt({
        ctype: options.ctype,
        cid: options.cid,
        site: 'A',
      });
    } else if (options.chnl || options.epsd) {
      this.updateShareCnt({
        chnl: options.chnl,
        epsd: options.epsd,
        div: 'S',
        site: 'A',
      });
    }
  }
}
