/**
 * 회원 기능 모음
 */
export class Member {
  constructor(utilThis) {
    this._funcs = utilThis;
    this.refreshKey = this._funcs.config.cookieName.JA_MEM_SSO_REFRESH;
    this.refreshDateKey = this._funcs.config.cookieName.JA_MEM_SSO_REFRESH_DATE;
    this.paidKey = this._funcs.config.cookieName.JA_MEM_PAID;
    this.paidDateKey = this._funcs.config.cookieName.JA_MEM_PAID_REFRESH_DATE;
    this.paidRefreshCookie = this._funcs.config.cookieName.JA_MEM_PAID_REFRESH_COOKIE;
  }

  /**
   * refresh token 저장 (이메일 회원만)
   */
  storeRefreshToken() {
    // const memInfo = this.getInfo();
    // if (memInfo.typeName === 'email') {
    const newRefreshKey = this._funcs.cookie.get(this.refreshKey);
    if (newRefreshKey && newRefreshKey !== '') {
      localStorage.setItem(this.refreshKey, this._funcs.cookie.get(this.refreshKey).replaceAll('"', ''));
      localStorage.setItem(this.refreshDateKey, new Date().format('yyyy-MM-dd HH:mm:ss'));
    } else {
      this.removeRefreshToken();
    }
    // }
  }

  /**
   * refresh token 제거
   */
  removeRefreshToken() {
    localStorage.removeItem(this.refreshKey);
    localStorage.removeItem(this.refreshDateKey);
  }

  /**
   * refresh 회워 유지를 위한 API 호출 쿠키갱신
   */
  executeRefreshToken(storedToken, reload) {
    const _this = this;
    this._funcs.ajax.get({
      url: this._funcs.config.accountApiPath + 'mem/login/refresh',
      beforeSend(xhr) {
        xhr.setRequestHeader('refresh-token', storedToken.replaceAll('"', ''));
      },
      withCredentials: true,
      success(result) {
        const resultStatus = String(result.status);
        // 200 : 로그인 쿠키(JAMemSSOInfo) 만료된 사용자 갱신, 101 : 하루 한번 갱신
        if (resultStatus === '200' || resultStatus === '101') {
          // 갱신일 저장
          localStorage.setItem(_this.refreshDateKey, new Date().format('yyyy-MM-dd HH:mm:ss'));
          // 신규 토큰 저장
          if (result.data && result.data.memSeq > 0 && result.data.jaMemSSORefresh !== '') {
            localStorage.setItem(_this.refreshKey, result.data.jaMemSSORefresh);
          }
          // 로그인 쿠키(JAMemSSOInfo) 만료된 사용자는 페이지 리로드
          if (resultStatus === '200' && reload) {
            _this.pageReload();
          }
          // eslint-disable-next-line no-console
          console.log('Request Token Refresh Success, Result-Status : ' + resultStatus);
        } else {
          _this.removeRefreshToken();
          // eslint-disable-next-line no-console
          console.log('Request Token Refresh Fail, Result-Status : ' + resultStatus);
        }
      },
      fail() {
        _this.removeRefreshToken();
      },
      error() {
        _this.removeRefreshToken();
      },
    });
  }

  /**
   * refresh check
   */
  checkRefresh() {
    const stored_token = localStorage.getItem(this.refreshKey);
    if (this.isMem()) {
      if (stored_token && stored_token !== '') {
        const stored_refreshDate = localStorage.getItem(this.refreshDateKey);
        if (stored_refreshDate && stored_refreshDate !== '') {
          const tokenTodayDiff = this.dateDiff(
            new Date().format('yyyy-MM-dd HH:mm:ss'),
            stored_refreshDate.toDate().format('yyyy-MM-dd HH:mm:ss'),
            'D'
          );
          // 로그인 유지 기능 변경한 이후 로그인 한 사용자
          if (tokenTodayDiff >= 1) {
            // 마지막 갱신일 확인하여 1일(24시간) 이상이면 다시 갱신
            this.executeRefreshToken(stored_token, false);
          }
        } else {
          // 로그인 유지 기능을 체크한 기존 사용자
          this.executeRefreshToken(stored_token, false);
        }
      } else {
        // 로그인 유지 기능을 체크하지 않은 기존 로그인 사용자는 유지기능이 기본이 됐기 때문에 쿠키에 담긴 토큰을 스토리지에 저장한다.
        this.storeRefreshToken();
      }
    } else if (!this.isMem()) {
      if (stored_token && stored_token !== '') {
        // 로그인 쿠키는 없는데 로그인 유지 기능 토큰이 있는 경우 (로그인 유지 체크된 기존 사용자)
        // 어카운트 API에 토큰을 넘겨 로그인 및 쿠키를 생성하고 리로드하여 로그인 상태로 보이게 한다.
        this.executeRefreshToken(stored_token, true);
      }
    }
  }

  /**
   * 로컬스토리지 이용권 정보 제거
   */
  removeRefreshPaidInfo() {
    localStorage.removeItem(this.paidKey);
    localStorage.removeItem(this.paidDateKey);
  }

  /**
   * 로컬스토리지 이용권 갱신요청 플래그 쿠키 제거 (결제쪽에서 갱신이 필요하면 넘겨줌)
   */
  removeRefreshPaidCookie() {
    this._funcs.cookie.set(this.paidRefreshCookie, '');
    this._funcs.cookie.remove(this.paidRefreshCookie);
  }

  /**
   * 로컬스토리지 이용권 정보 갱신
   */
  executeRefreshPaidInfo(callback) {
    const _this = this;
    // const memInfo = this.getInfo();
    this._funcs.ajax.get({
      // url: this._funcs.config.paymentApiPath + 'billing/getpaymentinfo/' + memInfo.seq.toString(),
      url: this._funcs.config.apiPath + 'member.api.pay.payment',
      withCredentials: true,
      success(result) {
        if (result) {
          // eslint-disable-next-line no-console
          console.log('request paid info success');
          if (result._PURCHASE_DATA) {
            localStorage.setItem(_this.paidKey, JSON.stringify(result._PURCHASE_DATA).toString());
          } else {
            localStorage.setItem(_this.paidKey, '');
          }
          localStorage.setItem(_this.paidDateKey, new Date().format('yyyy-MM-dd HH:mm:ss'));

          if (callback) {
            callback(result?._PURCHASE_DATA || null);
          }
        } else {
          // eslint-disable-next-line no-console
          console.log('request paid info fail');
          _this.removeRefreshPaidInfo();
        }
      },
      fail() {
        _this.removeRefreshPaidInfo();
      },
      error() {
        _this.removeRefreshPaidInfo();
      },
    });
  }

  /**
   * 로컬스토리지 이용권 정보 갱신 체크
   */
  checkPaidRefresh() {
    let nextJob = '';
    if (this.isMem()) {
      const refreshPaid_cookie = this._funcs.defaultValue(this._funcs.cookie.get(this.paidRefreshCookie), 'N');
      if (refreshPaid_cookie === 'Y') {
        nextJob = 'refresh';
      } else {
        const stored_paid = this._funcs.defaultValue(localStorage.getItem(this.paidKey), '');
        const stored_paid_date = this._funcs.defaultValue(localStorage.getItem(this.paidDateKey), '');
        if (stored_paid !== '' && stored_paid_date !== '') {
          if (stored_paid_date.toDate()) {
            const paidHourDiff = this.dateDiff(
              new Date().format('yyyy-MM-dd HH:mm:ss'),
              stored_paid_date.toDate().format('yyyy-MM-dd HH:mm:ss'),
              'H'
            );
            if (paidHourDiff >= 1) {
              nextJob = 'refresh';
            }
          } else {
            nextJob = 'delete';
          }
        } else if (stored_paid === '' && stored_paid_date === '') {
          nextJob = 'refresh';
        } else if (stored_paid !== '' && stored_paid_date === '') {
          nextJob = 'delete';
        }
      }
    } else {
      nextJob = 'delete';
    }
    if (nextJob === 'refresh') {
      this.executeRefreshPaidInfo();
      this.removeRefreshPaidCookie();
    } else if (nextJob === 'delete') {
      this.removeRefreshPaidInfo();
    }
  }

  getPaidInfo() {
    const paidInfo = localStorage.getItem(this.paidKey);
    if (paidInfo && paidInfo !== '') {
      return JSON.parse(paidInfo);
    }
    return null;
  }

  getPaidInfoPromise() {
    if (!this.isMem()) {
      return new Promise(resolve => {
        resolve(null);
      });
    }

    const info = this.getPaidInfo();

    if (info) {
      return new Promise(resolve => {
        resolve(info);
      });
    }

    return new Promise(resolve => {
      this.executeRefreshPaidInfo(info => {
        resolve(info);
      });
    });
  }

  pageReload() {
    location.reload();
  }

  /**
   * 회원 체크
   * @returns true 또는 false
   */
  isMem() {
    const ssoInfo = this._funcs.cookie.get(this._funcs.config.cookieName.JA_MEM_SSO_INFO);
    if (!ssoInfo) {
      // 혹시라도 남아있는 회원정보 제거
      this._funcs.cookie.remove(
        this._funcs.config.cookieName.MOKA_MEM,
        '/',
        this._funcs.config.domainCookieInfo.DOMAIN
      );
      return false;
    }
    const mem = this.getInfo();
    if (!mem) {
      return false;
    }
    if (mem.loginStatus === 'logout') {
      return false;
    }
    return true;
  }

  /**
   * 회원 정보 가져오기
   * @returns object 또는 null
   */
  getInfo() {
    try {
      const member = JSON.parse(this._funcs.cookie.get(this._funcs.config.cookieName.MOKA_MEM));
      member.loginStatus = member.sign === 'Y' ? 'login' : 'logout';
      // typeName
      switch (member.type) {
        case 'ML01':
          member.typeName = 'joins';
          break;
        case 'ML02':
          member.typeName = 'naver';
          break;
        case 'ML03':
          member.typeName = 'kakao';
          break;
        case 'ML04':
          member.typeName = 'google';
          break;
        case 'ML05':
          member.typeName = 'facebook';
          break;
        case 'ML06':
          member.typeName = 'apple';
          break;
        case 'ML07':
          member.typeName = 'email';
          break;
        default:
          member.typeName = '';
      }
      return member;
    } catch (e) {
      return null;
    }
  }

  /**
   * 회원정보 쿠키 제거
   */
  removeMemInfo() {
    this._funcs.cookie.remove(window.COOKIE_NAME.MOKA_MEM, '/', this._funcs.config.domainCookieInfo.DOMAIN);
    this._funcs.cookie.remove(window.COOKIE_NAME.JA_MEM_SSO_INFO, '/', this._funcs.config.domainCookieInfo.DOMAIN);
    this._funcs.cookie.remove(this.refreshKey, '/', this._funcs.config.domainCookieInfo.DOMAIN);
    this.removeRefreshToken();
  }

  /**
   * 날짜 차이
   */
  dateDiff(sdt, edt, diffType = 'D') {
    let convTime = 0;
    if (diffType.toUpperCase() === 'S') {
      convTime = 1000;
    } else if (diffType.toUpperCase() === 'M') {
      convTime = 1000 * 60;
    } else if (diffType.toUpperCase() === 'H') {
      convTime = 1000 * 3600;
    } else {
      convTime = 1000 * 3600 * 24;
    }
    const startDate = sdt.toDate();
    const endDate = edt.toDate();
    const dateDiff = Math.floor((startDate.getTime() - endDate.getTime()) / convTime);
    return dateDiff;
  }
}
