import { App } from '@/_modules/utils/utils/class/utils.app';
import { Chart } from '@/_modules/utils/utils/class/utils.chart';
import { Share } from '@/_modules/utils/utils/class/utils.share';
import { Bookmark } from '@/_modules/utils/utils/class/utils.bookmark';
import { Widget } from '@/_modules/utils/utils/class/utils.widget';
import { Format } from '@/_modules/utils/utils/class/utils.format';
import { Member } from '@/_modules/utils/utils/class/utils.member';
import { Device } from '@/_modules/utils/utils/class/utils.device';
import { Ajax } from '@/_modules/utils/utils/class/utils.ajax';
import { Pcid } from '@/_modules/utils/utils/class/utils.pcid';
import { Cookie } from '@/_modules/utils/utils/class/utils.cookie';
import { MyFavo } from '@/_modules/utils/utils/class/utils.myFavo.js';

const { $ } = window;

export class Utils {
  constructor(config) {
    this.config = config;
    this.app = new App(this);
    this.chart = new Chart();
    this.share = new Share(this);
    this.bookmark = new Bookmark(this);
    this.myFavo = new MyFavo(this);
    this.widget = new Widget(this);
    this.format = new Format(this);
    this.member = new Member(this);
    this.device = new Device(this);
    this.ajax = new Ajax(this);
    this.pcid = new Pcid(this);
    this.cookie = new Cookie(this);

    // pcid 생성
    this.pcid.set();

    // check refresh
    this.member.checkRefresh();

    // paid check refresh
    this.member.checkPaidRefresh();

    // 서버 시간
    this.serverDaytime = (function (__Ndaytime) {
      return __Ndaytime ? (__Ndaytime.pr.ymday + ' ' + __Ndaytime.pr.hsTime).toDate() : new Date();
    })(window.__Ndaytime);
  }

  /**
   * 윈도우 사이즈 조회
   * @returns { width, height }
   */
  getWindowSize() {
    return {
      width: window.innerWidth || document.documentElement.clientWidth || document.body.clientWidth,
      height: window.innerHeight || document.documentElement.clientHeight || document.body.clientHeight,
    };
  }

  /**
   * element 사이즈 조회
   * @param {Element} ele dom element
   * @returns { width, height }
   */
  getElementSize(ele) {
    return ele
      ? { width: ele.innerWidth || ele.clientWidth, height: ele.innerHeight || ele.clientHeight }
      : { width: -1, height: -1 };
  }

  /**
   * 윈도우의 스크롤Y 조회
   * @returns scrollY
   */
  getScrollY() {
    return window.scrollY || window.pageYOffset;
  }

  /**
   * 메타값 조회
   * @param {string} name meta name
   * @returns meta value
   */
  getMetaValue(name) {
    const metaTag = $('meta');
    let rtn = '';
    let $this = null;

    metaTag.each(function () {
      $this = $(this);
      if ($this.attr('property') !== undefined && $this.attr('property') === name) {
        rtn = $this.attr('content');
      } else if ($this.attr('name') !== undefined && $this.attr('name') === name) {
        rtn = $this.attr('content');
      }
    });

    return rtn;
  }

  /**
   * object -> queryString 으로 변환
   * @param {object} obj param object
   * @returns queryString
   */
  objectToQueryString(obj) {
    const arr = Object.keys(obj);

    if (arr.length < 1) {
      return '';
    }

    const [isLast] = arr.slice(-1);

    return (
      '?' +
      arr.reduce(function (all, cu) {
        // eslint-disable-next-line
        return all + cu + '=' + (encodeURIComponent(obj[cu]) || '') + (cu == isLast ? '' : '&');
      }, '')
    );
  }

  /**
   * queryString -> object 으로 변환
   * @param {string} str queryString
   * @returns object
   */
  queryStringToObject(str) {
    return decodeURI(str || location.search)
      .split(/[\?\&]/g)
      .filter(function (t) {
        return t !== '';
      })
      .reduce(function (all, cu) {
        const s = cu.split('=');
        try {
          all[s[0]] = decodeURIComponent(s[1] || '');
        } catch (e) {
          all[s[0]] = unescape(s[1] || '');
        }
        return all;
      }, {});
  }

  /**
   * object -> formData 로 변환
   * @param {any} val value
   * @param {any} formData 폼 데이터
   * @param {string} namespace 키명
   * @returns formData
   */
  objectToFormData(val, formData, namespace) {
    namespace = namespace || '';
    formData = formData || new FormData();

    if (typeof val !== 'undefined' && val !== null) {
      if (val instanceof Date) {
        formData.append(namespace, val.toISOString());
      } else if (val instanceof Array) {
        for (let i = 0; i < val.length; i++) {
          this.objectToFormData(val[i], formData, namespace + '[' + i + ']');
        }
      } else if (typeof val === 'object' && !(val instanceof File)) {
        for (const propertyName in val) {
          if (val.hasOwnProperty(propertyName)) {
            this.objectToFormData(
              val[propertyName],
              formData,
              namespace ? namespace + '.' + propertyName : propertyName
            );
          }
        }
      } else if (val instanceof File) {
        formData.append(namespace, val);
      } else {
        formData.append(namespace, val);
      }
    }
    return formData;
  }

  /**
   * t2p
   * @param {string} imgSrc 이미지 src
   * @returns 썸네일 이미지 -> 고화질로 변경
   */
  t2p(imgSrc) {
    return imgSrc ? imgSrc.replace(/(\.tn_\d{0,3}\.jpg)$/, '').replace('.thumb.jpg', '') : imgSrc;
  }

  /**
   * srcset 생성
   * @param {string} imgSrc 이미지 src (도메인없을 경우 PDS_PATH 추가)
   * @param {number} options.width width
   * @param {number} options.height height
   * @returns srcset array [1x, 2x]
   */
  getSrcset(imgSrc, options) {
    if (!options.width || !options.height || !imgSrc) {
      return [];
    }

    // 도메인 없을 경우 PDS_PATH 추가
    imgSrc = imgSrc.indexOf('http') < 0 ? this.config.pdsPath + imgSrc : imgSrc;

    // cf-images는 ston 처리 안함
    if (imgSrc.indexOf('cf-images') > -1) {
      return [imgSrc];
    }

    return [
      imgSrc + '/_ir_' + String(options.width) + 'x' + String(options.height) + '_/',
      imgSrc + '/_ir_' + String(options.width * 2) + 'x' + String(options.height * 2) + '_/',
    ];
  }

  /**
   * 서비스 src 생성
   * @param {string} imgSrc 이미지 src (도메인없을 경우 PDS_PATH 추가)
   * @param {number} width width
   * @param {number} height height
   * @returns src
   */
  getSrc(imgSrc, width, height) {
    if (!width || !height || !imgSrc) {
      return imgSrc || '';
    }

    // cf-images는 ston 처리 안함
    if (imgSrc.indexOf('cf-images') > -1) {
      return imgSrc;
    }

    return this.getSrcset(this.t2p(imgSrc), {
      width,
      height,
    })[0];
  }

  /**
   * 자동기사 + js에서 데이터 삽입하는 제목에 한해서 사용
   * @param {string} text 문자열
   * @returns escapeHtml
   */
  escapeHtml(text) {
    return (text || '').replace(/"/g, '&quot;').replace(/'/g, '&apos;').replace(/\</g, '&lt;').replace(/\>/g, '&gt;');
  }

  /**
   * 파라미터로 전달 보낼 문자열 변환
   * @param {string} text 문자열
   * @returns escapeHtmlParam
   */
  escapeHtmlParam(text) {
    return (text || '').replace(/&/g, '&amp;').replace(/\</g, '&lt;').replace(/\>/g, '&gt;');
  }

  /**
   * 1234 -> 1,234
   * 12345 -> 1.2만
   * 123456789 -> 1.2억
   * @param {number} number 변형할 수
   * @returns 변형된 문자
   */
  cutNum(number) {
    if (!number) {
      return number;
    }

    // 두번째 소수점에서 반올림 처리
    const fixedNum = Number(number.toFixed(1));

    if (fixedNum < 10000) {
      return fixedNum.toLocaleString('ko-KR');
    } else if (fixedNum < 100000000) {
      return Number((fixedNum / 10000).toFixed(1)).toLocaleString('ko-KR') + '만';
    }

    return Number((fixedNum / 100000000).toFixed(1)).toLocaleString('ko-KR') + '억';
  }

  /**
   * 구독상품과 관련된 링크를 구한다
   * @param {object} subData 구독 데이터
   * @returns [구독상품 링크, 구독상품의 컨텐츠 링크]
   */
  getSubscribeLink(subData) {
    subData = subData || {};

    let CONTENT_URL = '';
    let SCB_TARGET_URL = '';

    if (subData.CHANNEL_TYPE && subData.CHANNEL_ID) {
      // 구독상품의 링크 셋팅
      SCB_TARGET_URL = this.config.webPath;
      if (subData.CHANNEL_TYPE === 'PKG') {
        if (subData.PKG_DIV === 'I') {
          SCB_TARGET_URL += '/issue/' + subData.CHANNEL_ID;
        } else if (subData.PKG_DIV === 'T') {
          SCB_TARGET_URL += '/topic/' + subData.CHANNEL_ID;
        } else if (subData.PKG_DIV === 'S') {
          SCB_TARGET_URL += '/series/' + subData.CHANNEL_ID;
        }
      } else if (subData.CHANNEL_TYPE === 'REPORTER') {
        SCB_TARGET_URL += '/reporter/' + subData.CHANNEL_ID;
      } else if (subData.CHANNEL_TYPE === 'JPOD') {
        SCB_TARGET_URL += '/jpod/channel/' + subData.CHANNEL_ID;
      } else if ((subData.CHANNEL_TYPE === 'NEWSLETTER' || subData.CHANNEL_TYPE === 'LETTER') && subData.UTM_CONTENT) {
        // UTM_CONTENT 필요
        SCB_TARGET_URL += '/newsletter/' + subData.UTM_CONTENT;
      }
    }

    if (subData.CAT_DIV && subData.TOTAL_ID) {
      // 구독상품에 걸린 컨텐츠의 링크 셋팅
      CONTENT_URL = this.config.webPath;
      if (subData.CAT_DIV === 'A') {
        CONTENT_URL += '/article/' + subData.TOTAL_ID;
      } else if (subData.CAT_DIV === 'D') {
        CONTENT_URL += '/digitalspecial/' + subData.TOTAL_ID;
      } else if (subData.CAT_DIV === 'J') {
        CONTENT_URL += '/jpod/episode/' + subData.TOTAL_ID;
      } else if (subData.CAT_DIV === 'B') {
        CONTENT_URL = subData.BOARD_URL + '/' + subData.TOTAL_ID;
      } else if (subData.CAT_DIV === 'L') {
        CONTENT_URL += '/newsletter/' + subData.UTM_CONTENT + '/' + subData.TOTAL_ID;
      } else if (subData.CAT_DIV === 'F') {
        CONTENT_URL += '/atoz/' + subData.TOTAL_ID;
      } else if (subData.CAT_DIV === 'P') {
        CONTENT_URL += '/pdf/' + subData.TOTAL_ID;
      }
    }

    return [SCB_TARGET_URL, CONTENT_URL];
  }

  /**
   * javascript 로드
   * @param {string} props.id js id
   * @param {string} props.src js src (필수)
   * @param {func} props.onload js onload 함수
   */
  scriptLoader(props) {
    if (!props.src) {
      return;
    }

    const d = document;
    const s = 'script';
    let js = null;

    if (!document.getElementById(props.id)) {
      js = d.createElement(s);
      js.type = 'text/javascript';
      js.src = props.src;
      js.async = true;
      js.defer = true;
      if (props.id) {
        js.id = props.id;
      }
      if (props.onload && typeof props.onload === 'function') {
        js.onload = props.onload;
        js.onreadystatechange = function () {
          if (this.readyState === 'complete' || this.readyState === 'loaded') {
            props.onload.call(this);
          }
        };
      }
      document.head.appendChild(js);
    } else if (props.onload) {
      // 이미 로드되어있으면 onload만 실행
      props.onload.call(this);
    }
  }

  /**
   * 연관페이지1: https://stg-www.joongang.co.kr/article/25098868?js=localmodule
   * 연관페이지2: https://stg-www.joongang.co.kr/jpod/episode/910?js=localmodule
   */
  addLikeEvent() {
    // eslint-disable-next-line consistent-this
    const _self = this;

    $('.empathy_wrap button').click(function () {
      const $this = $(this);
      const $btnLike = $('.empathy_wrap .btn_like');
      const $btnDislike = $('.empathy_wrap .btn_dislike');
      const section = $('#section').val();
      const datas = {
        dev: _self.device.dev,
        pcid: _self.cookie.get(window.COOKIE_NAME.PCID),
      };

      if (section === 'jpod') {
        datas.chnl = $('#chnl').val();
        datas.epsd = $('#contentId').val();
      } else {
        datas.cid = $('#contentId').val();
        datas.ctype = $('#srType').val();
      }

      if ($this.hasClass('active')) {
        const voteId = $('.empathy_wrap > .comment_recomm').attr('data-id');
        _self.deleteMylike(section, voteId, function () {
          const count = $this.find('.count').text();
          $this.find('.count').text(Number(count) - 1);
          $this.toggleClass('active');
          $('.empathy_wrap > .comment_recomm').removeAttr('data-id');
          // plus 공감 영역 업데이트
          _self.setPlusLikeCnt($btnLike);
        });
      } else if ($this.hasClass('btn_like')) {
        datas.site = 'L';
        if ($btnDislike.hasClass('active')) {
          const voteId = $('.empathy_wrap > .comment_recomm').attr('data-id');
          _self.deleteMylike(section, voteId, function () {
            $btnDislike.toggleClass('active');
            const count = $btnDislike.find('.count').text();
            $btnDislike.find('.count').text(Number(count) - 1);
            $('.empathy_wrap > .comment_recomm').removeAttr('data-id');

            _self.setLikeCnt(section, datas, function (result) {
              $btnLike.toggleClass('active');
              const count = $btnLike.find('.count').text();
              $btnLike.find('.count').text(Number(count) + 1);
              $('.empathy_wrap > .comment_recomm').attr('data-id', result._INSERT_SEQ._DATA);
              // plus 공감 영역 업데이트
              _self.setPlusLikeCnt($btnLike);
            });
          });
        } else {
          _self.setLikeCnt(section, datas, function (result) {
            const count = $this.find('.count').text();
            $this.find('.count').text(Number(count) + 1);
            $this.toggleClass('active');
            $('.empathy_wrap > .comment_recomm').attr('data-id', result._INSERT_SEQ._DATA);
            // plus 공감 영역 업데이트
            _self.setPlusLikeCnt($btnLike);
          });
        }
      } else {
        datas.site = 'H';
        if ($btnLike.hasClass('active')) {
          const voteId = $('.empathy_wrap > .comment_recomm').attr('data-id');
          _self.deleteMylike(section, voteId, function () {
            $btnLike.toggleClass('active');
            const count = $btnLike.find('.count').text();
            $btnLike.find('.count').text(Number(count) - 1);
            $('.empathy_wrap > .comment_recomm').removeAttr('data-id');
            // plus 공감 영역 업데이트
            _self.setPlusLikeCnt($btnLike);

            _self.setLikeCnt(section, datas, function (result) {
              $btnDislike.toggleClass('active');
              const count = $btnDislike.find('.count').text();
              $btnDislike.find('.count').text(Number(count) + 1);
              $('.empathy_wrap > .comment_recomm').attr('data-id', result._INSERT_SEQ._DATA);
            });
          });
        } else {
          _self.setLikeCnt(section, datas, function (result) {
            const count = $this.find('.count').text();
            $this.find('.count').text(Number(count) + 1);
            $this.toggleClass('active');
            // eslint-disable-next-line no-console
            console.log(result._INSERT_SEQ._DATA);
            $('.empathy_wrap > .comment_recomm').attr('data-id', result._INSERT_SEQ._DATA);
            // plus 공감 영역 업데이트
            _self.setPlusLikeCnt($btnLike);
          });
        }
      }
    });
  }

  setPlusLikeCnt($objBtnLike) {
    if ($('div.plus_empathy').length > 0) {
      const likeCount = $objBtnLike.find('.count').text();
      $('div.plus_empathy').find('.count').text(Number(likeCount));
    }
  }

  setLikeCnt(type, datas, callback) {
    this.ajax.post({
      url: this.config.apiPath + (type === 'jpod' ? 'jpod.like.insert' : 'like.insert'),
      data: datas,
      withCredentials: true,
      success(result) {
        if (result._SUCCESS._DATA) {
          if (typeof callback !== 'undefined') {
            callback(result);
          }
        }
      },
    });
  }

  deleteMylike(type, id, callback) {
    this.ajax.post({
      url: this.config.apiPath + (type === 'jpod' || type === 'J' ? 'jpod.like.delete' : 'like.delete'),
      data: { id },
      withCredentials: true,
      success(result) {
        if (result._SUCCESS._DATA) {
          if (typeof callback !== 'undefined') {
            callback(result);
          }
        }
      },
    });
  }

  getMylike() {
    const section = $('#section').val() === 'jpod' ? 'J' : $('#srType').val();
    const contentId = $('#contentId').val();
    const datas = {
      pcid: this.cookie.get(window.COOKIE_NAME.PCID),
      ctype: section,
      cid: contentId,
    };

    this.ajax.get({
      url: this.config.apiPath + (section === 'jpod' || section === 'J' ? 'jpod.like.my' : 'like.my.on'),
      data: datas,
      withCredentials: true,
      success(result) {
        const shrDiv = $('#section').val() === 'jpod' ? 'J' : $('#srType').val();
        const contentId = $('#contentId').val();
        const $btnLike = $('.empathy_wrap .btn_like');
        const $btnDislike = $('.empathy_wrap .btn_dislike');
        $.each(result._DATA, function (index, data) {
          // eslint-disable-next-line eqeqeq
          if (data.SHR_DIV == shrDiv && contentId == data.UNIQ_NO.toString()) {
            if (data.SHR_SITE === 'L') {
              // eslint-disable-next-line eqeqeq
              if ($btnLike.find('.count').text() == '0') {
                $btnLike.find('.count').text(1);
              }
              $btnLike.addClass('active');
            } else {
              // eslint-disable-next-line eqeqeq
              if ($btnDislike.find('.count').text() == '0') {
                $btnDislike.find('.count').text(1);
              }
              $btnDislike.addClass('active');
            }
            $('.empathy_wrap > .comment_recomm').attr('data-id', data.SEQ_NO);
          }
        });
      },
    });
  }

  /**
   * 빈값 여부
   * @param value 값
   * @returns {boolean} 빈값 여부
   */
  empty(value) {
    if (value === null) {
      return true;
    }
    if (typeof value === 'undefined') {
      return true;
    }
    if (typeof value === 'string' && value === '') {
      return true;
    }
    if (Array.isArray(value) && value.length < 1) {
      return true;
    }
    if (
      typeof value === 'object' &&
      value.constructor.name === 'Object' &&
      Object.keys(value).length < 1 &&
      Object.getOwnPropertyNames(value) < 1
    ) {
      return true;
    }
    if (typeof value === 'object' && value.constructor.name === 'String' && Object.keys(value).length < 1) {
      return true;
    }
    return false;
  }

  /**
   * 값이 없을 경우 substitute값으로 대체
   * @param {*} value target
   * @param {*} substitute 대체
   * @returns {*} 값
   */
  defaultValue(value, substitute) {
    if (this.empty(substitute)) {
      substitute = '';
    }
    return this.empty(value) ? substitute : value;
  }

  /**
   * 뒤로가기로 들어온 페이지인지 확인
   * @returns true || false
   */
  isBackForwardPage() {
    if (window.performance.navigation) {
      // eslint-disable-next-line eqeqeq
      return window.performance.navigation.type == window.performance.navigation.TYPE_BACK_FORWARD;
    } else if (window.performance.getEntriesByType) {
      // eslint-disable-next-line eqeqeq
      return window.performance.getEntriesByType('navigation')[0].type == 'back_forward';
    }

    return false;
  }

  /**
   * swiper container 리턴 (본인, 부모, 자식한테 다 찾음. 순서: 본인 => 부모 => 자식)
   * @param {Element} el element
   * @returns swiper container
   */
  getSwiperContainer(el) {
    // eslint-disable-next-line no-nested-ternary
    const $container = el.classList.contains('swiper-container')
      ? $(el)
      : $(el).parents('.swiper-container').length > 0
      ? $(el).parents('.swiper-container')
      : $(el).find('.swiper-container');

    return $container.length > 0 ? $container[0] : null;
  }

  /**
   * swiper instance 리턴
   * @param {Element} el element
   * @returns swiper instance
   */
  getSwiperInstance(el) {
    return this.getSwiperContainer(el) ? this.getSwiperContainer(el).swiper : null;
  }

  /**
   * AB documentFragment 안의 노드를 포함한 swiper target 리턴
   * @param {string} selector
   * @returns [ target ] 타겟 element 리스트
   */
  findSwiperTarget(selector) {
    const target = [];

    $(selector).each(function () {
      target.push(this);
    });

    $('template').each(function () {
      const sub = this.content.querySelectorAll(selector);
      if (sub.length > 0) {
        sub.forEach(function (el) {
          target.push(el);
        });
      }
    });

    return target;
  }

  /**
   * 텍스트 강조 => bbs 검색에 사용함
   * @param {string} fullText 전체 텍스트
   * @param {string} keyword 강조할 단어
   * @returns 강조 처리된 텍스트
   */
  emKeyword(fullText, keyword) {
    // 21.12.08 키워드 escape 추가
    keyword = this.escapeHtmlParam(keyword);
    const orgin = fullText.split(/(?=<[a-z]+.*?\>)/g);
    // var noTag = fullText.split(/<[a-z]+.*?\>/g)
    const noTag = orgin.map(p => {
      const before = p.replace(/<[a-z]+.*?\>/g, '').replace(/<\/[a-z]+.*?\>/g, '');
      return { before, after: before };
    });

    noTag.forEach(p => {
      p.after = p.before.replace(keyword, '<em style="color:#f6330a">' + keyword + '</em>');
    });

    return orgin
      .map((p, idx) => {
        return p.replace(noTag[idx].before, noTag[idx].after);
      })
      .join('');
  }

  /**
   * html tag 제거
   * @param text 텍스트
   * @returns {*}
   */
  extractHtml(text) {
    return text.replace(/(<([^>]+)>)/gi, '');
  }

  debounce(fn, timeout) {
    let timer;
    return e => {
      if (timer) {
        clearTimeout(timer);
      }
      timer = setTimeout(() => fn(e), timeout ?? 200);
    };
  }

  throttle(fn, timeout) {
    let timer;
    return e => {
      if (!timer) {
        timer = setTimeout(() => {
          fn(e);
          timer = null;
        }, timeout ?? 200);
      }
    };
  }
}
