import { setFbAsyncInit } from '@/_modules/utils/utils/utils.global';
const { $ } = window;

/**
 * 연관페이지: https://stg-www.joongang.co.kr/opinion?js=localmodule
 * - data-widget-type="sns" html에 해당 어트리뷰트 있는 부분 그려줌
 */
export class Widget {
  constructor(utilThis) {
    this._funcs = utilThis;

    // eslint-disable-next-line consistent-this
    const _self = this;

    $.fn.widgetDepartmentSns = function () {
      const $this = $(this);
      _self.departmentSns({
        category: $(this).data('widget-category'),
        onload($dom) {
          $this.append($dom);
          setFbAsyncInit(function () {
            window.FB.init({
              appId: window.FB_APP_ID,
              autoLogAppEvents: true,
              xfbml: true,
              version: 'v17.0',
            });
          });
        },
      });
    };
  }

  /**
   * 부서별 페이스북 위젯 변환
   * @param {string} options.category sns category
   * @param {func} options.onload js 로드 후 실행
   */
  departmentSns(options) {
    options = options || {};

    const category = (options.category || 'politics').toLowerCase();
    const SNS_URI = {
      joongang: {
        text: '중앙일보 FB',
        link: 'https://www.facebook.com/joongang',
      },
      politics: {
        text: '정치부',
        link: 'https://www.facebook.com/joinspolitics',
      },
      money: {
        text: '경제부',
        link: 'https://www.facebook.com/joinsmoney',
      },
      society: {
        text: '사회부',
        link: 'https://www.facebook.com/joinssociety',
      },
      world: {
        text: '국제부',
        link: 'https://www.facebook.com/joonganginternational',
      },
      sports: {
        text: '스포츠',
        link: 'https://www.facebook.com/joongangilbosports',
      },
      culture: {
        text: '문화부',
        link: 'https://www.facebook.com/jmoonhwa',
      },
      opinion: {
        text: '오피니언',
        link: 'https://www.facebook.com/pages/%EC%A4%91%EC%95%99%EC%9D%BC%EB%B3%B4-%EB%85%BC%EC%84%A4%EC%9C%84%EC%9B%90%EC%8B%A4/756556791101437?fref=nf',
      },
      travel: {
        text: 'Week',
        link: 'https://www.facebook.com/weeknplus',
      },
      retirement: {
        text: '반퇴시대',
        link: 'https://www.facebook.com/theore88',
      },
      magazinem: {
        text: '매거진M',
        link: 'https://www.facebook.com/magazineM2017',
      },
      ourhistory: {
        text: '아워히스토리',
        link: 'https://www.facebook.com/ourhistoryO/',
      },
      sunday: {
        text: 'SUNDAY',
        link: 'https://www.facebook.com/joongangsunday',
      },
      peoplemic: {
        text: '시민마이크',
        link: 'https://www.facebook.com/peoplemic',
      },
      lifestyle: {
        text: '중앙일보 강남인류',
        link: 'https://www.facebook.com/itisyourstyle',
      },
    };
    const data = SNS_URI[category];

    // eslint-disable-next-line eqeqeq, no-eq-null
    if (data == null) {
      return;
    }

    if (this._funcs.device.msie && this._funcs.device.msieVersion <= 7) {
      return;
    }

    const $dom = $('#widget_dsns_tmpl2').tmpl({
      LINK: data.link,
      TEXT: data.text,
    });

    if ($dom) {
      this._funcs.scriptLoader({
        id: 'facebook-jssdk',
        src: 'https://connect.facebook.net/ko_KR/sdk.js#xfbml=1&version=v17.0&appId=' + window.FB_APP_ID,
        onload() {
          if (typeof options.onload === 'function') {
            options.onload.call(this, $dom);
          }
        },
      });
    }
  }
}
